import React, { useState } from 'react'
import { errorNotify, successNotify } from '../../../../../Helper/Toast';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import { cloudUrl, formats, modules } from '../../../../../Helper/Helper';
import { useUpdateAdminBlogPageMutation } from '../../../../../api/adminHome';

const Section2 = ({ data },) => {
    const [imagePreview, setImagePreview] = useState('');
    const [adminBlogPageUpdate] = useUpdateAdminBlogPageMutation();


    const [sectionData, setSectionData] = useState({
        text: data.text,
        image: data.image.filename
    })
    const [isLoading, setIsLoading] = useState(false)
    const [image, setImage] = useState()
    const imageHandler = (e) => {
        const file = e.target.files[0];
        setImage(file)
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setImagePreview(event.target.result);
            };
            reader.readAsDataURL(file);
        }
    }
    const onTextChange = (value) => {
        setSectionData((prev) => { return { ...prev, text: value } })
    }
    const handleSectionUpdate = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formData = new FormData();
        formData.append("sectionName", `section2`);
        if (image) {
            formData.append("image", image);
        }
        formData.append("text", sectionData.text);
        setSectionData({
            image: '',
            text: '',
        })
        const res = await adminBlogPageUpdate({ formData });
        if (res.data) {
            successNotify("Blog Page Section 2 Updated");
            setSectionData({
                image: res?.data?.blog?.section2?.image.filename,
                text: res?.data?.blog?.section2?.text,
            })

        }
        else {
            errorNotify("Something Went Wrong")
        }
        setIsLoading(false);
    }

    return (
        <div>
            <Form onSubmit={handleSectionUpdate}>
                <Row>
                    <Col md={12}>
                        <Form.Label>Text</Form.Label>
                        <ReactQuill style={{ backgroundColor: "white" }} name="text" className="mb-3" theme="snow" modules={modules} formats={formats} value={sectionData?.text} onChange={onTextChange} />
                    </Col>
                    <Col md={12}>
                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Image</Form.Label>
                            <Form.Control type="file" style={{ fontSize: "13px", paddingBottom: "7px" }} onChange={imageHandler} />
                        </Form.Group>
                        <img src={imagePreview ? imagePreview : `${cloudUrl}/${sectionData?.image}`} alt={`${sectionData?.image}`} style={{ width: "120px" }} />
                    </Col>
                </Row>
                <hr />
                <div className={"text-end my-4"}>
                    <Button type='Submit' className="cms_submit_btn">{isLoading ? <Spinner animation='border' size='sm' /> : 'Submit'} </Button>
                </div>
            </Form>
        </div>
    )
}


export default Section2
