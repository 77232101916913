import Dashboard from "../Pages/Dashboard/Dashboard"
import Profile from "../Pages/Profile/Profile.js"
import FooterEmails from "../Pages/FooterEmails/FooterEmails.js"
import Home from "../Pages/Home/Home.js"
import About from "../Pages/About/About.js"
import Testimonials from "../Pages/Testimonials/Testimonials.js"
import Services from "../Pages/Services/Services.js"
import Blogs from "../Pages/Blogs/Blogs.js"
import BlogPage from '../Pages/BlogPage/BlogPage.js'
import Footer from "../Pages/Footer/Footer.js"

export const AdminSideBarItems = [
    {
        path: "/admin/dashboard",
        icon: "",
        title: "Dashboard",
        isSubNav: false,
    },
    {
        path: "/admin/profile",
        icon: '',
        title: "Profile",
        isSubNav: false,
    },
    {
        path: "/admin/footer-emails",
        icon: "",
        title: "Footer Emails",
        isSubNav: false,
    },
    {
        path: "",
        icon: "",
        title: "Edit Pages",
        isSubNav: true,
        subNav: [
            {
                path: "/admin/edit/home",
                icon: "",
                title: "Home",
            },
            {
                path: "/admin/edit/about",
                icon: "",
                title: "About",
            },
            {
                path: "/admin/edit/testimonials",
                icon: "",
                title: "Testimonials",
            },
            {
                path: "/admin/edit/services",
                icon: "",
                title: "Services",
            },
            {
                path: "/admin/edit/blogs",
                icon: "",
                title: "Blogs",
            },
            {
                path: "/admin/edit/blog-page",
                icon: "",
                title: "Blog Page",
            },
            {
                path: "/admin/edit/footer",
                icon: "",
                title: "Footer",
            },

        ]
    },
];

export const AdminRoutes = [
    {
        path: '/admin/dashboard',
        component: <Dashboard />
    },
    {
        path: '/admin/profile',
        component: <Profile />
    },
    {
        path: '/admin/footer-emails',
        component: <FooterEmails />
    },
    {
        path: '/admin/edit/home',
        component: <Home />
    },
    {
        path: '/admin/edit/about',
        component: <About />
    },
    {
        path: '/admin/edit/testimonials',
        component: <Testimonials />
    },
    {
        path: '/admin/edit/services',
        component: <Services />
    },
    {
        path: '/admin/edit/blogs',
        component: <Blogs />
    },
    {
        path: '/admin/edit/blog-page',
        component: <BlogPage />
    },
    {
        path: '/admin/edit/footer',
        component: <Footer />
    }
]