import React from 'react'
import { Button, Col, Form, InputGroup, Modal, Row, Spinner } from 'react-bootstrap'
import { useState } from 'react';
import defaultImage from '../../../../assets/signature.png'
import ReactQuill from 'react-quill';

const modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
        [{ list: "ordered" }, { list: "bullet" }]
    ]
};
const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "color",
    "background"
]

const BlogModal = ({ show, handleClose, handleBlogCreate, isLoading }) => {

    const [blogData, setBlogData] = useState({
        heading: "",
        text: "",
        link: "",
        image: "",
        thumbnail: "",
    })
    const [image, setImage] = useState('');
    const [imagePreview, setImagePreview] = useState();

    const [thumbnail, setThumbnail] = useState('');
    const [thumbnailPreview, setThumbnailPreview] = useState();

    const handleSubmit = (e) => {
        handleBlogCreate(e, blogData, image, thumbnail);
        setBlogData({
            heading: "",
            text: "",
            link: "",
            image: "",
            thumbnail: "",
        })
        setImage('');
        setImagePreview('');
        setThumbnail('');
        setImagePreview('');
    }


    const onDataChange = (e, name) => {
        setBlogData((prev) => { return { ...prev, [name]: e.target.value } })
    }
    const onTextChange = (value) => {
        setBlogData((prev) => { return { ...prev, text: value } })
    }
    const imageHandler = (e, flag) => {
        const file = e.target.files[0];
        if (flag === 'image') {
            setImage(file)
            if (file) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    setImagePreview(event.target.result);
                };
                reader.readAsDataURL(file);
            }
        }
        else if (flag === 'thumbnail') {
            setThumbnail(file)
            if (file) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    setThumbnailPreview(event.target.result);
                };
                reader.readAsDataURL(file);
            }
        }
    }
    const closeHandler = () => {
        console.log("edit");

        setBlogData({
            heading: "",
            text: "",
            link: "",
            image: "",
            thumbnail: "",
        })
        setImagePreview();
        setThumbnailPreview();
        handleClose();
    }


    return (
        <Modal centered show={show} onHide={closeHandler}>
            <Modal.Header closeButton>
                <Modal.Title>New Blog Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group onSubmit={handleSubmit} controlId="formFile" className="mb-3">
                    <Row>
                        <Col md={12} >
                            <Form.Label style={{
                                fontWeight: "bold", marginLeft: "5px", marginTop: "15px"
                            }} >Heading</Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control placeholder='Enter Heading Name' name='heading' value={blogData.heading} onChange={(e) => onDataChange(e, "heading")} />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} >
                            <Form.Label style={{ fontWeight: "bold", marginLeft: "5px", marginTop: "15px" }}>Image</Form.Label>
                            <Form.Control type="file" style={{ fontSize: "13px", paddingBottom: "7px" }} onChange={(e) => imageHandler(e, 'image')} />
                            <img src={imagePreview ? imagePreview : defaultImage} alt='' style={{ width: "120px" }} />
                        </Col>
                        <Col md={6} >
                            <Form.Label style={{ fontWeight: "bold", marginLeft: "5px", marginTop: "15px" }}>Thumbnail</Form.Label>
                            <Form.Control type="file" style={{ fontSize: "13px", paddingBottom: "7px" }} onChange={(e) => imageHandler(e, 'thumbnail')} />
                            <img src={thumbnailPreview ? thumbnailPreview : defaultImage} alt={''} style={{ width: "120px" }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} >
                            <Form.Label style={{ fontWeight: "bold", marginLeft: "5px", marginTop: "15px" }}>Text</Form.Label>
                            <ReactQuill placeholder='Enter Blog text' style={{ backgroundColor: "white" }} name="text" className="mb-3" theme="snow" modules={modules} formats={formats}
                                value={blogData.text} onChange={onTextChange} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} >
                            <Form.Label style={{ fontWeight: "bold", marginLeft: "5px", marginTop: "15px" }}>Link</Form.Label>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon3">
                                    https://
                                </InputGroup.Text>
                                <Form.Control id="basic-url" aria-describedby="basic-addon3" name='url' value={blogData.link} onChange={(e) => onDataChange(e, "link")} />
                            </InputGroup>
                        </Col>
                    </Row>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeHandler}>
                    Close
                </Button>
                <Button disabled={isLoading ? true : false} onClick={handleSubmit} type='Submit' className="cms_submit_btn">{isLoading ? <Spinner animation='border' size='sm' /> : "Submit"}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default BlogModal
