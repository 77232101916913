import React, { useState } from "react";
import "../Auth.css";
import { Container, Form, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import TestimonialHeader from "../../../../Components/Header/TestimonialHeader";
import { useLoginUserMutation } from "../../../../api/adminHome";
import { errorNotify, successNotify } from "../../../../Helper/Toast";

const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [loginUser] = useLoginUserMutation();
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (loginData.email.length === 0 || loginData.password.length === 0) {
      alert("please fill up all fields")
      return
    }
    try {
      const res = await loginUser(loginData);
      console.log(res);
      if (res.data) {
        successNotify("Login in Successful");
        localStorage.setItem('token', res.data.token);
        navigate("/admin/dashboard");
      }
      else {
        errorNotify(`${res.error.data.message}`)
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error.message)
    }

  };

  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <TestimonialHeader />
        </Row>
      </Container>
      <div className="auth_main">
        <Container>
          <div className="auth_box">
            <h2>Reetu Sukhramani</h2>
            <h3>Log In</h3>

            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-2">
                <Form.Control
                  type="email"
                  placeholder="Email"
                  value={loginData.email}
                  onChange={(e) =>
                    setLoginData({
                      ...loginData,
                      email: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={loginData.password}
                  onChange={(e) =>
                    setLoginData({
                      ...loginData,
                      password: e.target.value,
                    })
                  }
                />
              </Form.Group>

              <div>
                <button className="submit_btn" type="submit">
                  {isLoading ? <Spinner animation='border' size='sm' /> : 'Submit'}
                </button>
              </div>
            </Form>

          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default Login;