import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";

const TestimonialHeader = () => {
  const navigate = useNavigate();

  const [headerShow, setHeaderShow] = useState(true);

  return (
    <div className="testimonial_header">
      <Container>
        <Row>
          <Col
            className={
              headerShow ? "p-0 sidebar hide show_web" : "p-0 sidebar show_web"
            }
          >
            <Header
              headerShow={headerShow}
              setHeaderShow={setHeaderShow}
              goToQuote={null}
              showQuote={false}
            />
          </Col>
        </Row>
      </Container>
      <Container>
        <div className="testimonial_top_header">

          <div>
            {headerShow && (
              <img
                className="expand-2"
                src="/images/navbar_menu.gif"
                alt=""
                onClick={() => setHeaderShow(!headerShow)}
              />
            )}
            <img
              src="/images/retu_testi_logo.png"
              alt=""
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
            />
          </div>

          <button onClick={() => navigate("/contact-us")}>Contact Us</button>
        </div>
      </Container>
    </div>
  );
};

export default TestimonialHeader;
