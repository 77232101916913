import React, { useState } from "react";
import "../Auth.css";
import { Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import TestimonialHeader from "../../../../Components/Header/TestimonialHeader";
import { useRegisterUserMutation } from "../../../../api/adminHome";

const Register = () => {
  const navigate = useNavigate();
  const [registerUser, response] = useRegisterUserMutation();
  const [registerData, setRegisterData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (registerData.password.length === 0 || registerData.confirmPassword.length === 0) {
      alert("Passwords cannot be empty!");
      return
    }

    if (registerData.password !== registerData.confirmPassword) {
      alert("Passwords do not match");
      return
    }
    const res = await registerUser(registerData);
    console.log(res);
    localStorage.setItem('token', res.data.token);
    navigate("/");
  };

  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <TestimonialHeader />
        </Row>
      </Container>
      <div className="auth_main">
        <Container>
          <div className="auth_box">
            <h2>Reetu Sukhramani</h2>
            <h3>Sign Up</h3>

            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Control
                  type="email"
                  placeholder="Email"
                  value={registerData.email}
                  onChange={(e) =>
                    setRegisterData({
                      ...registerData,
                      email: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={registerData.password}
                  onChange={(e) =>
                    setRegisterData({
                      ...registerData,
                      password: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  type="password"
                  placeholder="Confirm Password"
                  value={registerData.confirmPassword}
                  onChange={(e) =>
                    setRegisterData({
                      ...registerData,
                      confirmPassword: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <div>
                <button className="submit_btn" type="submit">
                  Submit
                </button>
              </div>
            </Form>

            <span>Or continue with</span>
            <div className="straight_line"></div>
            <div className="auth_social">
              <img src="/images/google_auth_icon.png" alt="" />
              <img
                src="/images/fb_auth_icon.png"
                alt=""
                style={{ width: "50px" }}
              />
              <img src="/images/insta_auth_icon.png" alt="" />
            </div>

            <p>
              Already have an account?
              <strong onClick={() => navigate("/login")}>Log in</strong>
            </p>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default Register;