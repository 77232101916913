import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { BsChatQuoteFill } from 'react-icons/bs'
import { MdOutlineMiscellaneousServices, MdEmail } from "react-icons/md";
import './Dashboard.css'
import { Link, useNavigate } from 'react-router-dom';
const Dashboard = () => {
  const navigate = useNavigate();
  return (
    <div className='page_responsive'>
      <h1>Dashboard</h1>
      <h2>Welcome Admin</h2>
      <div onClick={() => navigate("/admin/lead")} className='dashboard-item-container' >
        <h4>Total Leads</h4>
        <h4>140</h4>
        <BsChatQuoteFill />
      </div>
      <div onClick={() => navigate("/admin/footer-emails")} className='dashboard-item-container' >
        <h4>Total Emails</h4>
        <h4>1</h4>
        <MdEmail />
      </div>
      <div className='dashboard-link-container' >
        <h4>Quick Edit Links</h4>
        <div>
          <Link to="/admin/edit/home">Home</Link>
          <Link to="/admin/edit/about">About</Link>
          <Link to="/admin/edit/testimonials">Testimonials</Link>
          <Link to="/admin/edit/blogs">Blogs</Link>
          <Link to="/admin/edit/footer">Footer</Link>
        </div>
      </div>
    </div>
  )
}

export default Dashboard