import React, { useState } from 'react'
import SectionFAQ from './SectionFAQ'
import { Button } from 'react-bootstrap'
import FAQModal from './FAQModal.js';
const SectionFAQS = ({ id, data }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const faqModal = <FAQModal id={id} show={show} handleClose={handleClose} />
    return (
        <div>
            <div className={"text-end my-4"}>
                <Button onClick={() => setShow(true)} type='Submit' className="cms_submit_btn">Add FAQ </Button>
            </div>
            {data.map((item) => <SectionFAQ sectionId={id} data={item} />)}
            {faqModal}
        </div>
    )
}

export default SectionFAQS
