import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { IoMdArrowDropright, IoMdArrowDropdown } from "react-icons/io"
import Signature from '../../../assets/signature.png'
import { Collapse } from "@mui/material";
import "./Sidebar.css";

const DashboardSidebar = (props) => {
    const location = useLocation();

    const [sideBar, setSideBar] = useState(true);
    const [subNav, setSubNav] = useState(false);
    const [openRow, setOpenRow] = useState({ name: null, close: false })

    const classes = (path) => {
        if (path === location.pathname) return "nav_active";
        return "";
    };

    const handleRowsCollapse = (name) => {
        setSubNav(!subNav)
        setOpenRow({ name: name, close: !openRow.close })
    }

    return (
        <div className={sideBar ? "sidebar active" : "sidebar"}>
            <div className={"admin-logo"}>
                <img src={Signature} alt="Signature" />
            </div>
            <ul className="nav_list">
                {props?.sideBarItems?.map((item, index) => {
                    if (item.path) {
                        return (
                            <li key={item.path} className={`${classes(item.path)}`}>
                                <Link to={item.path}>
                                    {item.icon}
                                    <span>{item.title}</span>
                                </Link>
                            </li>
                        );
                    }
                    else {
                        return (
                            <li key={item.path} className={`${classes(item.path)}`}>
                                <div className="collapse_div" onClick={() => handleRowsCollapse(item.title)}>
                                    <Link to={'#'}>
                                        {item.icon}
                                        <span>{item.title}</span>
                                        {
                                            item?.title === openRow.name && openRow.close === true ?
                                                <IoMdArrowDropdown className={'subNav'} /> : <IoMdArrowDropright className={'subNav'} />
                                        }
                                    </Link>
                                </div>

                                {
                                    item.subNav ?
                                        <Collapse in={item?.title === openRow.name && openRow.close === true} unmountOnExit>
                                            <React.Fragment>
                                                {
                                                    item.subNav.map((subItem, index) => (
                                                        <li className={`${classes(subItem.path)} sub_active ml-4`} key={index}>
                                                            <div className="sub_nav">
                                                                <Link to={subItem.path} style={{ paddingTop: '10px', paddingLeft: '0px', paddingBottom: "10px" }}>
                                                                    {subItem?.icon}
                                                                    <span>{subItem.title}</span>
                                                                </Link>
                                                            </div>
                                                        </li>
                                                    ))
                                                }
                                            </React.Fragment>
                                        </Collapse>
                                        : null
                                }
                            </li>
                        )
                    }
                })}
            </ul>
        </div>
    );
};
export default DashboardSidebar;