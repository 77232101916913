import React, { useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import "./Header.css";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useGetAdminBlogQuery, useGetAdminServicesQuery } from "../../api/adminHome";

const Header = ({ headerShow, setHeaderShow, goToQuote, showQuote }) => {
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showBlogs, setShowBlogs] = useState(false);

  const { data: servicesData, isLoading: servicesDataLoading } = useGetAdminServicesQuery();
  const { data: blogsData, isLoading: blogsDataLoading } = useGetAdminBlogQuery();
  console.log(blogsData);

  return (
    <React.Fragment>
      <div
        className={
          headerShow
            ? "main_header show_web expand_nav"
            : "main_header show_web"
        }
      >
        {headerShow && (
          <img
            className="expand"
            src="/images/navbar_menu.gif"
            alt=""
            onClick={() => setHeaderShow(!headerShow)}
          />
        )}

        {!headerShow && (
          <>
            <img
              src="/images/navbar_menu.gif"
              alt=""
              onClick={() => setHeaderShow(!headerShow)}
            />
            <div className="main_navbar">
              <ul>
                <li onClick={() => navigate("/")}>Home</li>
                <li onClick={() => navigate("/about")}>About</li>
                <li>
                  <div className="menu_dropdown">
                    <span onClick={() => setShowDropdown(!showDropdown)}>
                      Services <BiChevronDown />
                    </span>
                    {showDropdown && (
                      <div className="service_dropdown">
                        {!servicesDataLoading && servicesData?.service ? <ul>
                          {servicesData.service.map((item, index) => <li onClick={() => navigate(`/service/${item.slug}`)} >{item?.section2?.heading}</li>)}
                        </ul> : null}
                      </div>
                    )}
                  </div>
                </li>
                <li>
                  <div className="menu_dropdown">
                    <span onClick={() => navigate("/blog")}  >
                      Blogs
                    </span>
                  </div>
                </li>
                <li onClick={() => navigate("/contact-us")}>Contact Us</li>
              </ul>
            </div>
          </>
        )}

        {showQuote && (
          <img
            src="/images/scroll_bar.png"
            className="scrollbar"
            alt=""
            onClick={goToQuote}
          />
        )}
      </div>

      <div className="show_mob">
        <Navbar expand="lg" className="bg-body-tertiary">
          <Container>
            <Navbar.Brand onClick={() => navigate("/")}></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link onClick={() => navigate("/")}>Home</Nav.Link>
                <Nav.Link onClick={() => navigate("/about")}>About</Nav.Link>
                <Nav.Link>
                  <div className="menu_dropdown">
                    <span onClick={() => setShowDropdown(!showDropdown)}>
                      Services <BiChevronDown />{" "}
                    </span>

                    {showDropdown && (
                      <div className="service_dropdown">
                        {!servicesDataLoading && servicesData.service ? <ul>
                          {servicesData.service.map((item, index) => <li onClick={() => navigate(`/service/${item.slug}`)} >{item?.section2?.heading}</li>)}
                        </ul> : null}
                      </div>
                    )}
                  </div>
                </Nav.Link>
                <Nav.Link>
                  <li>
                    <div className="menu_dropdown" style={{ marginRight: "25px" }}>
                      <span>
                        <span onClick={() => navigate("/blog")}>Blogs </span>
                      </span>

                    </div>
                  </li>
                </Nav.Link>
                <Nav.Link onClick={() => navigate("/")}>Contact Us</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </React.Fragment>
  );
};
export default Header;
