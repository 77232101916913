import React, { useEffect } from 'react'
import { Form, Button, Col, Row, Spinner, InputGroup } from 'react-bootstrap'

import { useState } from 'react';
import ReactQuill from 'react-quill';
import { successNotify } from '../../../../../../Helper/Toast.js';
import { cloudUrl } from '../../../../../../Helper/Helper.js';

const modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
        [{ list: "ordered" }, { list: "bullet" }]
    ]
};
const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "color",
    "background"
]

const Section1 = ({ data, onUpdate, updateRes }) => {
    console.log(updateRes);
    const [imagePreview, setImagePreview] = useState('');
    const [sectionData, setSectionData] = useState({
        heading: data.heading,
        text: data.text,
        image: data.image.filename
    })
    const [image, setImage] = useState()

    useEffect(() => {
        if (updateRes) {
            setSectionData({
                heading: updateRes?.data?.about?.section1?.heading,
                text: updateRes?.data?.about?.section1?.text,
                image: updateRes?.data?.about?.section1?.image?.filename
            });
            setIsLoading(false);
        }
    }, [updateRes]);

    const [isLoading, setIsLoading] = useState(false);

    const imageHandler = (e) => {
        const file = e.target.files[0];
        setImage(file)
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setImagePreview(event.target.result);
            };
            reader.readAsDataURL(file);
        }
    }
    const onDataChange = (e, name) => {
        setSectionData((prev) => { return { ...prev, [name]: e.target.value } })
    }
    const onTextChange = (value) => {
        setSectionData((prev) => { return { ...prev, text: value } })

    }
    const onUpdateSectionHandler = (e) => {
        setIsLoading(true);
        onUpdate({ e, section: 1, sectionData: { ...sectionData, image } })
        setSectionData({
            heading: "",
            text: "",
            image: ""
        });
        setImage('');
        setImagePreview('')
        successNotify("About Section 1 Updated");
    }
    return (
        <div>
            <Form onSubmit={onUpdateSectionHandler}>
                <Row>
                    <Col md={12}>
                        <Form.Label>Main Heading</Form.Label>
                        <InputGroup className="mb-3">
                            <Form.Control placeholder='Enter Heading Name' name='heading' value={sectionData.heading} onChange={(e) => onDataChange(e, "heading")} />
                        </InputGroup>
                    </Col>
                    <Col md={12}>
                        <Form.Label>Text</Form.Label>
                        <ReactQuill style={{ backgroundColor: "white" }} name="text" className="mb-3" theme="snow" modules={modules} formats={formats} value={sectionData.text} onChange={onTextChange} />
                    </Col>
                    <Col md={12}>
                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Image</Form.Label>
                            <Form.Control type="file" style={{ fontSize: "13px", paddingBottom: "7px" }} onChange={imageHandler} />
                        </Form.Group>
                        <img src={imagePreview ? imagePreview : `${cloudUrl}/${sectionData.image}`} alt={`${sectionData.image}`} style={{ width: "120px" }} />
                    </Col>
                </Row>
                <hr />
                <div className={"text-end my-4"}>
                    <Button type='Submit' className="cms_submit_btn">{isLoading ? <Spinner animation='border' size='sm' /> : 'Submit'} </Button>
                </div>

            </Form>
        </div>
    )
}

export default Section1
