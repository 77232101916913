import React from 'react';
import { Modal } from "react-bootstrap";
import "./SiteModal.css";
import { IoMdCloseCircleOutline } from "react-icons/io";

const SiteModal = ({ title, show, size, onCloseModal, showIcon, children }) => {
    return (
        <Modal show={show} size={size} backdrop="static" keyboard={false} className={"site_modal"}>
            <Modal.Header>
                <div className={'review_header'}>
                    <h4>{title}</h4>
                    {showIcon ? <IoMdCloseCircleOutline onClick={onCloseModal} /> : null}
                </div>
            </Modal.Header>
            <Modal.Body>{children}</Modal.Body>
        </Modal>
    );
};
export default SiteModal;