import React, { useState } from 'react'
import { Button, Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap'
import ReactQuill from 'react-quill'
import { formats, modules } from '../../../../../Helper/Helper'
import { useDeleteAdminFAQMutation, useUpdateAdminServiceFAQMutation } from '../../../../../api/adminHome'
import { errorNotify, successNotify } from '../../../../../Helper/Toast'

const SectionFAQ = ({ sectionId, data }) => {

    const [sectionData, setSectionData] = useState({
        heading: data.question,
        text: data.answer
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);

    const [updateFAQ] = useUpdateAdminServiceFAQMutation();
    const [deleteFAQ] = useDeleteAdminFAQMutation();
    const handleUpdateFAQ = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formData = {
            "faq": {

                "question": sectionData.heading,
                "answer": sectionData.text
            }
        }

        const res = await updateFAQ({ id: sectionId, faqId: data._id, formData });

        if (res.data) {
            successNotify("FAQ Updated");
        }
        else {
            errorNotify("Something went wrong");
        }

        setIsLoading(false);
    }
    const handleDeleteFAQ = async (e) => {
        e.preventDefault();
        setIsDeleteLoading(true);
        const res = await deleteFAQ({ id: sectionId, faqId: data._id });
        if (res.data) {
            successNotify("FAQ is Deleted");
        }
        else {
            errorNotify("Something went wrong");
        }
        setIsDeleteLoading(false);
    }

    const onDataChange = (e, name) => {
        setSectionData((prev) => { return { ...prev, [name]: e.target.value } })
    }

    const onTextChange = (value) => {
        setSectionData((prev) => { return { ...prev, text: value } })
    }
    return (
        <Row style={{ marginLeft: "40px" }} >

            <h6>Question</h6>
            <Col md={8}>
                <InputGroup className="mb-3">
                    <Form.Control placeholder='Enter Heading Name' name='heading' value={sectionData.heading} onChange={(e) => onDataChange(e, "heading")} />
                </InputGroup>
            </Col>
            <Row>
                <Col md={8}>
                    <Form.Label>Answer</Form.Label>
                    <ReactQuill style={{ backgroundColor: "white" }} name="text" className="mb-3" theme="snow" modules={modules} formats={formats} value={sectionData.text} onChange={onTextChange} />
                </Col>
                <Col md={4}>
                    <Button onClick={handleUpdateFAQ} style={{ marginTop: "75px" }} type='Submit' className="cms_submit_btn">{isLoading ? <Spinner animation='border' size='sm' /> : 'Edit'} </Button>
                    <Button onClick={handleDeleteFAQ} style={{ marginTop: "75px" }} type='Submit' className="cms_submit_btn">{isDeleteLoading ? <Spinner animation='border' size='sm' /> : 'Delete'} </Button>
                </Col>
            </Row>
        </Row>
    )
}

export default SectionFAQ
