import React from 'react'
import { Form, Button, Col, Row, Spinner } from 'react-bootstrap'
import DefaultImage from '../../../../../../assets/signature.png'
import { MdDelete } from "react-icons/md";
import { useState } from 'react';
import SiteModal from '../../../../../../Components/SiteModal/SiteModal.js';
import { useDeleteAdminAboutMutation } from '../../../../../../api/adminHome.js';
import { errorNotify, successNotify } from '../../../../../../Helper/Toast.js';
import { useEffect } from 'react';


import { cloudUrl } from '../../../../../../Helper/Helper.js';

const Section3 = ({ data, onUpdate, updateRes },) => {

    const [adminAboutDelete, result] = useDeleteAdminAboutMutation();

    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteLoading, setDeleteIsLoading] = useState(false);

    const [sectionData, setSectionData] = useState(data);
    const [show, setShow] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const [imagePreviews, setImagePreviews] = useState([]);
    const [images, setImages] = useState([]);
    const [addImage, setAddImage] = useState();
    const [addShow, setAddShow] = useState(false);
    const [addImagePreview, setAddImagePreview] = useState();

    useEffect(() => {
        if (updateRes) {
            setSectionData(updateRes.data.about.section3);
            setIsLoading(false);
            setDeleteIsLoading(false);
        }
    }, [updateRes]);

    const handleAdd = (e) => {
        e.preventDefault();
        if (e.target.name === 'Cancel') {
            if (addImagePreview) {
                setImages(prevImages => {
                    const newImages = [...prevImages];
                    newImages.pop();
                    return newImages;
                });
            }
        }
        setAddShow(!addShow);
        setSectionData([...sectionData])
    }

    const handleSectionUpdate = (e) => {
        e.preventDefault();
        setDeleteIsLoading(true);
        setIsLoading(true);
        if (images.length !== 0) {
            onUpdate({ e, section: 3, sectionData, images, addImage });
            setSectionData([]);
            setAddShow(false);
            setAddImage('');
            setImages([]);
            setAddImagePreview('');
            successNotify("About Section 3 Updated")
        } else {
            errorNotify("No Image is changes");
            setIsLoading(false);
        }

    }

    const deleteHandler = async (e) => {
        e.preventDefault();
        setDeleteIsLoading(true);
        setSectionData(prevSectionData => prevSectionData.filter(obj => obj._id !== deleteId));
        let data = sectionData.filter((obj) => obj._id !== deleteId)
        const formData = new FormData();
        formData.append("sectionName", `section3`);
        formData.append("sectionData", JSON.stringify(data));
        try {
            const res = await adminAboutDelete({ formData });
            console.log(res);
            successNotify("Image Deleted Successfully")
            setShow(!show);
        } catch (error) {
            errorNotify(error.messsage);
        }
        setDeleteIsLoading(false);
    }
    const deleteModalHandler = (e, deleteId) => {
        e.preventDefault();
        setDeleteId(deleteId);
        setShow(!show)
    }
    const imageHandler = (e, index) => {
        const file = e.target.files[0];
        setImages(prevImages => {
            const newImages = [...prevImages];
            newImages[index] = file;
            return newImages;
        });
        setSectionData(prev => {
            return prev.map((item, i) => {
                if (i === index) {
                    return {
                        ...item,
                        image: {
                            ...item.image,
                            isFile: 1,
                        },
                    };
                }
                return item;
            });
        });
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setImagePreviews(prevImages => {
                    const newImages = [...prevImages];
                    newImages[index] = event.target.result;
                    return newImages;
                });
            };
            reader.readAsDataURL(file);
        }
    }
    const addImageChangeHandler = (e) => {
        const file = e.target.files[0];
        if (addShow) {
            setImages(prevImages => {
                const newImages = [...prevImages, file];
                // newImages[index] = file;
                return newImages;
            });
        }
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setAddImagePreview(event.target.result);
            };
            reader.readAsDataURL(file);
        }
    }
    const modal = <SiteModal title="Delete" size={"lg"} show={show} showIcon={true} onCloseModal={() => setShow(!show)}>
        <div className='delete_modal'>
            <h5>Are you sure you want to delete this??</h5>

            <div>
                <button onClick={deleteHandler}>{isDeleteLoading ? <Spinner animation='border' size='sm' /> : 'Yes'}</button>
                <button onClick={() => setShow(!show)}>No</button>
            </div>
        </div>
    </SiteModal>

    const newSectionDataItem = <Col key={''} md={4}>
        <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Images</Form.Label>
            <Form.Control type="file" style={{ fontSize: "13px", paddingBottom: "7px" }} onChange={addImageChangeHandler} />
            <div style={{ width: "50%", aspectRatio: "1/1", display: "flex", justifyContent: "center", alignItems: "center", border: "1px solid black", borderRadius: "30px" }} >
                <img src={addImagePreview ? addImagePreview : DefaultImage} alt={`DefaultImage`} style={{ width: "120px" }} />
            </div>
        </Form.Group>
    </Col>
    return (
        <div>
            {modal}
            <Form>
                <Row className='home-section-container'>
                    <div className={"text-end my-4"}>
                        <Button type='Submit' className="cms_submit_btn" name={`${addShow ? "Cancel" : "Add"}`} onClick={handleAdd} >{`${addShow ? "Cancel" : "Add"}`}</Button>
                    </div>
                    <Row>
                        {sectionData.length !== 0 ? sectionData.map((item, index) => {
                            return (
                                <Col key={item._id} lg={4}>
                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Form.Label>Images{`${index + 1}`}</Form.Label>
                                        <Form.Control type="file" style={{ fontSize: "13px", paddingBottom: "7px" }} onChange={(e) => imageHandler(e, index)} />
                                        <button onClick={(e) => deleteModalHandler(e, item._id)} className='section_delete_btn' ><MdDelete /></button>
                                        <div style={{ marginTop: "15px", width: "50%", aspectRatio: "1/1", display: "flex", justifyContent: "center", alignItems: "center", border: "1px solid black", borderRadius: "30px" }} >
                                            <img src={imagePreviews[index] ? imagePreviews[index] : `${cloudUrl}/${item.image.filename}`} alt={`${item.image.filename}`} style={{ width: "120px" }} />
                                        </div>
                                    </Form.Group>
                                </Col>
                            )
                        }) : null}
                        {addShow ? newSectionDataItem : null}
                    </Row>
                    <div className={"text-end my-4"}>
                        <Button disabled={isLoading ? true : false} onClick={handleSectionUpdate} type='Submit' className="cms_submit_btn">{isLoading ? <Spinner animation='border' size='sm' /> : "Submit"} </Button>
                    </div>
                </Row>

            </Form>
        </div>
    )
}

export default Section3
