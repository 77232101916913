import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useGetAdminBlogQuery } from '../../api/adminHome';
import Loader from '../../Helper/Loader';
import { cloudUrl } from '../../Helper/Helper';

const OurBlog = () => {
    const { data, isLoading } = useGetAdminBlogQuery();

    console.log(data);
    return (
        <div>
            <div className="blog_main blog_page">
                <Container>
                    <h1>OUR BLOGS</h1>
                    {(!isLoading && data?.blogs) ?
                        <Row>
                            {data.blogs.map((item, index) => {
                                return (
                                    <Col key={item._id} md={3} className='blog_box_main' data-aos="fade-down">
                                        <a href={`/blog/${item._id}`} style={{ textDecoration: "none" }}>
                                            <div className="blog_box">
                                                <img src={`${cloudUrl}/${item.image?.filename}`} alt={`${item.image?.filename}`} />
                                                <div>
                                                    <p>{item.heading}</p>
                                                    <a href={`/blog/${item._id}`}>
                                                        Read More
                                                    </a>
                                                </div>
                                            </div></a>
                                    </Col>
                                )
                            })}
                        </Row> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Loader />
                        </div>
                    }


                </Container>
            </div>
        </div>
    )
}

export default OurBlog
