import React from "react";
import Sidebar from "../../../Components/Admin/Sidebar/Sidebar";
import { AdminSideBarItems } from "./Routes";
import Header from "../../../Components/Admin/Header/Header";
const Navbar = () => {
  return (
    <>
      <Header />
      <Sidebar sideBarItems={AdminSideBarItems} />
    </>
  );
};
export default Navbar;
