import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import OtherFooter from "../../../../Components/OtherFooter/OtherFooter";
import { useState } from "react";
import Header from "../../../../Components/Header/Header";
import TestimonialHeader from "../../../../Components/Header/TestimonialHeader";
import "../Services.css";
import FeedbackPage from "../../../../Components/Feedback/Feedback";
import Faqs from "../../../../Components/Faqs/Faqs";
import { Link } from "react-router-dom";
import { useGetSingleServiceQuery } from "../../../../api/adminHome";
import Loader from "../../../../Helper/Loader";
import { cloudUrl } from "../../../../Helper/Helper";

const RsMethod = () => {

  const [headerShow, setHeaderShow] = useState(true);
  const currentUrl = window.location.href;
  const id = currentUrl.substring(currentUrl.lastIndexOf('/') + 1);

  const { data, isLoading } = useGetSingleServiceQuery({ id });

  return (
    <React.Fragment>
      {!isLoading && data.service ?
        <>
          <Container fluid className="p-0">
            <Row>
              <TestimonialHeader />
            </Row>
            <Row className="mob_nav">
              <Header
                headerShow={headerShow}
                setHeaderShow={setHeaderShow}
                goToQuote={null}
                showQuote={false}
              />
            </Row>
            <Row style={{ position: "relative" }}>
              <Col className="p-0">
                <div className="health_section rs_method" style={{ backgroundImage: `url(${cloudUrl}/${data?.service?.section2?.image?.filename})` }} alt={`${cloudUrl}/${data?.service?.section2?.image?.filename}`}  >
                  <Container>
                    <div className="health_section_text">
                      <h2>
                        <span>{data?.service?.section2?.heading}</span>
                        <br />
                        <h2 dangerouslySetInnerHTML={{ __html: data?.service?.section2?.text }}>
                        </h2>
                      </h2>
                      <h4>JOIN THE PROGRAM</h4>
                      <button>
                        <Link to={`${data?.service?.section2?.link}`} target="_blank">
                          ENROLL NOW
                        </Link>
                      </button>
                    </div>
                  </Container>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="rs_section1">
            <Container>
              <Row className="justify-content-center align-items-center">
                <Col md={5}>
                  <div className="rs_section1_text">
                    <h5>{data?.service?.section3?.heading}</h5>
                    <div className="list_image" dangerouslySetInnerHTML={{ __html: data?.service?.section3?.text }}></div>
                  </div>
                </Col>
                <Col md={5}>
                  <div>
                    <img src={`${cloudUrl}/${data?.service?.section3?.image?.filename}`} alt={`${cloudUrl}/${data?.service?.section3?.image?.filename}`} />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="rs_section1">
            <Container>
              <Row className="justify-content-center align-items-center">
                <Col md={5}>
                  <div>
                    <img src={`${cloudUrl}/${data?.service?.section4?.image?.filename}`} alt={`${cloudUrl}/${data?.service?.section4?.image?.filename}`} className="looks_img" />
                  </div>
                </Col>
                <Col md={5}>
                  <div className="rs_section1_text">
                    <h5>{data?.service?.section4?.heading}</h5>
                    <div className="list_image" dangerouslySetInnerHTML={{ __html: data?.service?.section4?.text }}></div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* <div className="program_pillars">
            <Container>
              <Row className="justify-content-center align-items-center">
                <Col md={5}>
                  <div className="program_pillars_text">
                    <h5>{data?.service?.section5?.heading}</h5>

                    {data?.service?.section5?.content.map((item, index) => {
                      return (
                        <>
                          <h6 style={{ fontSize: "18px", fontWeight: "700" }}>{item?.heading}</h6>
                          <ul className="list_image sub-section-list" dangerouslySetInnerHTML={{ __html: item?.text }}></ul>
                        </>
                      )
                    })}
                  </div>
                </Col>
                <Col md={5}>
                  <div className="pillar_left">
                    <img src={`${cloudUrl}/${data?.service?.section5?.image?.filename}`} alt={`${cloudUrl}/${data?.service?.section5?.image?.filename}`} />
                  </div>
                </Col>
              </Row>
            </Container>
          </div> */}
          <div className="list_image deals_main" style={{ backgroundImage: `url(${cloudUrl}/${data?.service?.section6?.image?.filename})` }}>
            <Container>
              <Row className="justify-content-center">
                <Col md={10}>
                  <div className="deal_main_text">
                    <h3>{data?.service?.section6?.heading}</h3>
                    <p dangerouslySetInnerHTML={{ __html: data?.service?.section6?.text }}></p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <FeedbackPage />
          <div className="meet_coach">
            <Container>
              <Row className="align-items-center">
                <Col md={6}>
                  <div className="meet_coach_text">
                    <h1>{data?.service?.section7?.heading}</h1>

                    <p dangerouslySetInnerHTML={{ __html: data?.service?.section7?.text }}></p>
                  </div>
                </Col>
                <Col md={6}>
                  <div>
                    <img src={`${cloudUrl}/${data?.service?.section7?.image?.filename}`} alt={`${cloudUrl}/${data?.service?.section7?.image?.filename}`} />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <Faqs data={data.service.faqs} />
          <div className="food_blog rs_method">
            <Container>
              <Row className="justify-content-center">
                <Col md={5}>

                  <div>
                    <h3>{data?.service?.section8?.heading}</h3>
                    <button> <Link to={`${data?.service?.section8?.link}`} target="_blank">
                      ENROLL NOW
                    </Link>
                    </button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <OtherFooter />
        </>
        : <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Loader />
        </div>}

    </React.Fragment>
  );
};

export default RsMethod;
/*
 <ul>
                      <li><img src="/images/tick.png" alt="" /> Core values</li>
                      <li><img src="/images/tick.png" alt="" /> Primary and secondary goals</li>
                      <li><img src="/images/tick.png" alt="" /> Blood Makers & Health History</li>
                      <li><img src="/images/tick.png" alt="" /> Tangible & Intangible Progress Markers</li>
                    </ul>

                    <h6 style={{ fontSize: "18px", fontWeight: "700" }}>BODY</h6>
                    <ul>
                      <li> <img src="/images/tick.png" alt="" /> Body Image Flexibility</li>
                      <li> <img src="/images/tick.png" alt="" /> Body Image Functionality  </li>
                      <li> <img src="/images/tick.png" alt="" /> Neutral-Positive Body Image</li>
                      <li> <img src="/images/tick.png" alt="" /> Movement, Somatics, & Exercise</li>
                      <li> <img src="/images/tick.png" alt="" /> Stress Management</li>
                    </ul>

                    <h6 style={{ fontSize: "18px", fontWeight: "700" }}>FOOD</h6>
                    <ul>
                      <li> <img src="/images/tick.png" alt="" /> Nutritional Education</li>
                      <li> <img src="/images/tick.png" alt="" /> Food Inclusivity </li>
                      <li> <img src="/images/tick.png" alt="" /> Food Neutrality </li>
                      <li> <img src="/images/tick.png" alt="" /> Unconditional Permission to Eat</li>
                      <li> <img src="/images/tick.png" alt="" /> Food Freedom</li>
                      <li> <img src="/images/tick.png" alt="" /> Internal Cues & Awareness</li>
                    </ul>

                    <h6 style={{ fontSize: "18px", fontWeight: "700" }}>MINDSET</h6>
                    <ul>
                      <li> <img src="/images/tick.png" alt="" /> Mindfulness</li>
                      <li> <img src="/images/tick.png" alt="" /> Emotional Regulation</li>
                      <li> <img src="/images/tick.png" alt="" /> Habit Development</li>
                      <li> <img src="/images/tick.png" alt="" /> Self-compassion Development</li>
                    </ul>



 <div>
                      <img src="/images/tick.png" alt="" />
                      <p>
                        Equipping you with relevant, evidence-based nutritional education
                      </p>
                    </div>
                    <div>
                      <img src="/images/tick.png" alt="" />
                      <p>Instilling self-compassion & emotional regulation</p>
                    </div>
                    <div>
                      <img src="/images/tick.png" alt="" />
                      <p>Creating personalised health guidelines specific to your context</p>
                    </div>
                    <div>
                      <img src="/images/tick.png" alt="" />
                      <p>Breaking free from disordered eating patterns</p>
                    </div>
                    <div>
                      <img src="/images/tick.png" alt="" />
                      <p>Finding peace with food</p>
                    </div>
                    <div>
                      <img src="/images/tick.png" alt="" />
                      <p>Improving your body image</p>
                    </div>
                    <div>
                      <img src="/images/tick.png" alt="" />
                      <p>Adopting sustainable habits</p>
                    </div>
                    <div>
                      <img src="/images/tick.png" alt="" />
                      <p>Challenging limiting mindsets & beliefs</p>
                    </div>
                    <div>
                      <img src="/images/tick.png" alt="" />
                      <p>Understanding emotions and how to regulate them </p>
                    </div>
 */