import React from 'react'
import { Button, Col, Form, InputGroup, Modal, Row, Spinner } from 'react-bootstrap'
import { useState } from 'react';
import defaultImage from '../../../../assets/retu_signature1.png'
import ReactQuill from 'react-quill';
import { modules, formats } from '../../../../Helper/Helper';
import { useCreateAdminTestimonialMutation } from '../../../../api/adminHome';
import { errorNotify, successNotify } from '../../../../Helper/Toast';


const TestimonialModal = ({ show, handleClose }) => {

    const [testimonialData, setTestimonialData] = useState({
        createdBy: "",
        text: "",
        image: "",
    })
    const [image, setImage] = useState();
    const [imagePreview, setImagePreview] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const [createTestimonial] = useCreateAdminTestimonialMutation();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (testimonialData.createdBy === '' && testimonialData.text === '') {
            errorNotify("Testimonial is empty enter some feilds")
        }
        else {
            setIsLoading(true);
            const formData = new FormData();
            formData.append('createdBy', testimonialData.createdBy);
            formData.append('text', testimonialData.text);
            if (image) {
                formData.append('image', image);
            }

            const res = await createTestimonial(formData);
            if (res.data) {
                successNotify('Testimonial Created Successfully')
            }
            else {
                errorNotify("Something went wrong")
            }
            setTestimonialData({
                createdBy: "",
                text: "",
                image: ""
            })

            setIsLoading(false);
            handleClose();
        }
    }

    const onDataChange = (e, name) => {
        setTestimonialData((prev) => { return { ...prev, [name]: e.target.value } })
    }
    const onTextChange = (value) => {
        setTestimonialData((prev) => { return { ...prev, text: value } })
    }
    const imageHandler = (e, flag) => {
        const file = e.target.files[0];
        setImage(file)
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setImagePreview(event.target.result);
            };
            reader.readAsDataURL(file);
        }
    }
    const closeHandler = () => {
        setTestimonialData({
            createdBy: "",
            text: "",
            image: "",
        })
        setImage();
        setImagePreview();
        handleClose();
    }


    return (
        <Modal centered show={show} onHide={closeHandler}>
            <Modal.Header closeButton>
                <Modal.Title>New Testimonial Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group onSubmit={handleSubmit} controlId="formFile" className="mb-3">
                    <Row>
                        <Col md={12} >
                            <Form.Label style={{
                                fontWeight: "bold", marginLeft: "5px", marginTop: "15px"
                            }} >Created By</Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control placeholder='Enter Heading Name' name='createdBy' value={testimonialData.heading} onChange={(e) => onDataChange(e, "createdBy")} />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} >
                            <Form.Label style={{ fontWeight: "bold", marginLeft: "5px", marginTop: "15px" }}>Image</Form.Label>
                            <Form.Control type="file" style={{ fontSize: "13px", paddingBottom: "7px" }} onChange={(e) => imageHandler(e, 'image')} />
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                <img src={imagePreview ? imagePreview : defaultImage} alt='' style={{ width: "120px" }} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} >
                            <Form.Label style={{ fontWeight: "bold", marginLeft: "5px", marginTop: "15px" }}>Text</Form.Label>
                            <ReactQuill placeholder='Enter Blog text' style={{ backgroundColor: "white" }} name="text" className="mb-3" theme="snow" modules={modules} formats={formats}
                                value={testimonialData.text} onChange={onTextChange} />
                        </Col>
                    </Row>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeHandler}>
                    Close
                </Button>
                <Button disabled={isLoading ? true : false} onClick={handleSubmit} type='Submit' className="cms_submit_btn">{isLoading ? <Spinner animation='border' size='sm' /> : "Submit"}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default TestimonialModal
