import React from 'react'

const Footer = () => {
    return (
        <div className='page_responsive'>
            <h1>Footer</h1>
        </div>
    )
}

export default Footer
