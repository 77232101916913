import React from 'react'
import { Button, Col, Form, InputGroup, Modal, Row, Spinner } from 'react-bootstrap'
import { useState } from 'react';
import ReactQuill from 'react-quill';
import { useCreateAdminFAQMutation } from '../../../../../api/adminHome';
import { errorNotify, successNotify } from '../../../../../Helper/Toast';

const modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
        [{ list: "ordered" }, { list: "bullet" }]
    ]
};
const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "color",
    "background"
]

const FAQModal = ({ id, show, handleClose }) => {

    const [faqData, setFaqData] = useState({
        heading: "",
        text: "",
    })
    const [createFAQ] = useCreateAdminFAQMutation();

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formData = {
            "faq": {

                "question": faqData.heading,
                "answer": faqData.text
            }
        }

        const res = await createFAQ({ id, formData });

        if (res.data) {
            successNotify("New FAQ is Created");
            setFaqData({
                heading: "",
                text: "",
            })

        }
        else {
            errorNotify("Something went wrong");
        }
        handleClose();
        setIsLoading(false);

    }

    const onDataChange = (e, name) => {
        setFaqData((prev) => { return { ...prev, [name]: e.target.value } })
    }
    const onTextChange = (value) => {
        setFaqData((prev) => { return { ...prev, text: value } })
    }
    const closeHandler = () => {
        console.log("edit");

        setFaqData({
            heading: "",
            text: "",
        })
        handleClose();
    }


    return (
        <Modal centered show={show} onHide={closeHandler}>
            <Modal.Header closeButton>
                <Modal.Title>FAQ Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group onSubmit={handleSubmit} controlId="formFile" className="mb-3">
                    <Row>
                        <Col md={12} >
                            <Form.Label style={{
                                fontWeight: "bold", marginLeft: "5px", marginTop: "15px"
                            }} >Question</Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control placeholder='Question' name='heading' value={faqData.heading} onChange={(e) => onDataChange(e, "heading")} />
                            </InputGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12} >
                            <Form.Label style={{ fontWeight: "bold", marginLeft: "5px", marginTop: "15px" }}>Answer</Form.Label>
                            <ReactQuill placeholder='Answer' style={{ backgroundColor: "white" }} name="text" className="mb-3" theme="snow" modules={modules} formats={formats}
                                value={faqData.text} onChange={onTextChange} />
                        </Col>
                    </Row>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeHandler}>
                    Close
                </Button>
                <Button disabled={isLoading ? true : false} onClick={handleSubmit} type='Submit' className="cms_submit_btn">{isLoading ? <Spinner animation='border' size='sm' /> : "Create"}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default FAQModal
