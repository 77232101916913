import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../Blog/BlogRegular/BlogRegular";
import OtherFooter from "../../../Components/OtherFooter/OtherFooter";
import Header from "../../../Components/Header/Header";
import TestimonialHeader from "../../../Components/Header/TestimonialHeader";
import { Link } from "react-router-dom";

const Blog1 = () => {
    const [headerShow, setHeaderShow] = useState(true);
    return (
        <div className="blog_regular">
            <Container fluid className="p-0">
                <Row>
                    <TestimonialHeader />
                </Row>
                <Row className="mob_nav">
                    <Header
                        headerShow={headerShow}
                        setHeaderShow={setHeaderShow}
                        goToQuote={null}
                        showQuote={false}
                    />
                </Row>
            </Container>

            <div className="about_main about_page blog_regular_page blog-1">
                <Container className="SectionOne">
                    <div className="image-container">
                        {/* <img src="/images/BingeEating.png" alt="" /> */}
                        <div>
                            <h1>
                                The 90s Diet Culture Anthem: “Nothing tastes as good as skinny feels”
                            </h1>
                            <hr />
                            <div>
                                BY REETU SUKHRAMANI
                            </div>
                        </div>
                    </div>
                </Container>
            </div>

            <Container fluid className="SectionTwo">
                <Row className="justify-content-center">
                    <Col md={11} className="Main">
                        <div className="Para">
                            <p>
                                Diet culture is a patriarchal system designed to make women feel inadequate and unworthy.
                            </p>
                            <p>
                                There is a reason why women are continually reminded that they are too much or too little of *insert practically any adjective* by social media, peers, and co-habitants/family members.
                            </p>
                            <p>It helps the cash cow milk itself.</p>
                            <p>
                                Even though we are aware of this matrix, we are helpless to change it.
                            </p>
                            <h3>And here’s why:</h3>
                            <p>
                                Because when something is so familiar, it becomes comfortable.
                            </p>
                            <p>
                                But comfort isn’t always safe.
                            </p>
                            <p>
                                Comfort could cost us another generation of women feeling inadequate and unworthy.
                            </p>

                            <h3>So what now? </h3>

                            <p>
                                Should we just keep living this way by accepting things as they are because doing otherwise feels uncomfortable?
                            </p>
                            <p>
                                Or, for a brief moment, let's imagine what the alternative may look like.
                            </p>

                            <h3>A life where:</h3>

                            <ul className="SupportPeople">
                                <li>You can just eat your doughnut without feeling guilty</li>
                                <li>Your weight does not define your self-esteem</li>
                                <li>You don't have to justify your eating habits to anyone</li>
                                <li>You eat more than your partner and still feel absolutely fine</li>
                                <li>The desire to go out for a bite does not overwhelm you</li>
                                <li>Reading the calories on a menu does not rattle you</li>
                                <li>Exercise doesn’t mean punishment</li>
                                <li>You losing weight doesn’t mean you lose yourself</li>
                                <li>Health also means taking care of your emotional, psychological, economical, social, and intellectual well-being, as well as your physical well-being</li>
                                <li>Guilt, shame, and restriction doesn’t exist</li>
                            </ul>

                            <p>Doesn’t this sound so amazing? </p>
                            <p>If you think you are ready for this then fill in the form below and let’s chat? </p>

                            <button className="blog_btn"> <Link to={"https://docs.google.com/forms/d/17P7hXZXLOLouePep2OEJg_pR0jjrd0OE775lQ3PE65Q/edit"} target="_blank">
                                ENROLL NOW
                            </Link>
                            </button>
                        </div>
                    </Col>
                </Row>
            </Container>

            <OtherFooter />
        </div>
    );
};

export default Blog1;
