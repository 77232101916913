import React, { useState } from 'react'
import { Form, Button, Col, Row, Spinner, InputGroup } from 'react-bootstrap'
import { cloudUrl, formats, modules } from '../../../../../Helper/Helper.js';
import { useUpdateAdminServiceMutation } from '../../../../../api/adminHome.js';
import { errorNotify, successNotify } from '../../../../../Helper/Toast.js';
import ReactQuill from 'react-quill';


const Section8 = ({ data, id }) => {
    const [sectionData, setSectionData] = useState({
        heading: data.heading,
        link: data.link
    })

    const onDataChange = (e, name) => {
        setSectionData((prev) => { return { ...prev, [name]: e.target.value } })
    }

    const [editSection] = useUpdateAdminServiceMutation();
    const [isLoading, setIsLoading] = useState(false);

    const handleEditSection = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formData = new FormData();
        formData.append("sectionName", `section8`);

        formData.append('link', sectionData.link);
        formData.append('heading', sectionData.heading);

        const res = await editSection({ id: id, formData });
        if (res.data) {
            successNotify("Service Section Updated");
        }
        else {
            console.log(res.error);
            errorNotify("Something went wrong");
        }

        setIsLoading(false);

    }
    return (
        <div>
            <Form onSubmit={handleEditSection}>
                <Row>

                    <Col md={12}>
                        <Form.Label>Main Heading</Form.Label>
                        <InputGroup className="mb-3">
                            <Form.Control placeholder='Enter Heading Name' name='heading' value={sectionData.heading} onChange={(e) => onDataChange(e, "heading")} />
                        </InputGroup>
                    </Col>

                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon3">
                            https://
                        </InputGroup.Text>
                        <Form.Control id="basic-url" aria-describedby="basic-addon3" name='url' value={sectionData.link} onChange={(e) => onDataChange(e, "link")} />
                    </InputGroup>
                </Row>
                <hr />
                <div className={"text-end my-4"}>
                    <Button type='Submit' className="cms_submit_btn">{isLoading ? <Spinner animation='border' size='sm' /> : 'Submit'} </Button>
                </div>
            </Form>
        </div>
    )
}

export default Section8
