import React from 'react'
import Navbar from '../Admin/Routes/Navbar'
import { Navigate, Outlet } from 'react-router-dom'

const AdminLayout = () => {
  let token = localStorage.getItem("token");
  return (
    <React.Fragment>
      {token ? <React.Fragment>
        <Navbar />
        <Outlet />
      </React.Fragment> : <Navigate to={'/'} />}
    </React.Fragment>
  )
}

export default AdminLayout


