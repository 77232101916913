import React, { useState } from 'react'
import { Form, Button, Col, Row, Spinner } from 'react-bootstrap'
import { cloudUrl } from '../../../../../Helper/Helper.js';
import { useUpdateAdminServiceMutation } from '../../../../../api/adminHome.js';
import { errorNotify, successNotify } from '../../../../../Helper/Toast.js';


const Section1 = ({ data, id }) => {
    const [sectionData, setSectionData] = useState({
        image: data.image.filename
    })
    const [editSection] = useUpdateAdminServiceMutation();
    const [image, setImage] = useState();
    const [imagePreview, setImagePreview] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const imageHandler = (e) => {
        const file = e.target.files[0];
        setImage(file)
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setImagePreview(event.target.result);
            };
            reader.readAsDataURL(file);
        }
    }
    const handleEditSection = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formData = new FormData();
        if (image) {
            formData.append("sectionName", `section1`);
            formData.append('image', image);

            const res = await editSection({ id: id, formData });
            if (res.data) {
                successNotify("Service Section Updated");
            }
            else {
                errorNotify("Something went wrong");
            }
        }
        else {
            errorNotify('no data is changed');
        }
        setIsLoading(false);

    }
    return (
        <div>
            <Form onSubmit={handleEditSection}>
                <Row>
                    <Col md={12}>
                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Thumbnail</Form.Label>
                            <Form.Control type="file" style={{ fontSize: "13px", paddingBottom: "7px" }} onChange={imageHandler} />
                        </Form.Group>
                        <img src={imagePreview ? imagePreview : `${cloudUrl}/${sectionData.image}`} alt={`${sectionData.image}`} style={{ width: "120px" }} />
                    </Col>
                </Row>
                <hr />
                <div className={"text-end my-4"}>
                    <Button type='Submit' className="cms_submit_btn">{isLoading ? <Spinner animation='border' size='sm' /> : 'Submit'} </Button>
                </div>
            </Form>
        </div>
    )
}

export default Section1
