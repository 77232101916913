import React from "react";
import "./About.css";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import OtherFooter from "../../../Components/OtherFooter/OtherFooter";
import Header from "../../../Components/Header/Header";
import { useState } from "react";
import TestimonialHeader from "../../../Components/Header/TestimonialHeader";
import { Link, useNavigate } from "react-router-dom";
import certifiedSliderData from "../../../Util/CertifiedSliderData";
import instaData from "../../../Util/InstaData";
import { settings } from "../../../Helper/Helper";
import { useGetAdminAboutQuery } from "../../../api/adminHome";
import Loader from "../../../Helper/Loader";
import { cloudUrl } from "../../../Helper/Helper";
import { FaInstagram } from "react-icons/fa";

const About = () => {
  const [headerShow, setHeaderShow] = useState(true);
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetAdminAboutQuery();
  console.log(data);

  return (
    <React.Fragment>
      {(!isLoading && data.about) ? <>
        <Container fluid className="p-0">
          <Row>
            <TestimonialHeader />
          </Row>
          <Row className="mob_nav">
            <Header
              headerShow={headerShow}
              setHeaderShow={setHeaderShow}
              goToQuote={null}
              showQuote={false}
            />
          </Row>
        </Container>

        <div className="about_main about_page">
          <Container>
            <Row className="justify-content-center">
              <Col md={10}>
                <Row className="align-items-center">
                  <Col md={6}>
                    <div className="about_left_img">
                      <img src={`${cloudUrl}/${data?.about?.section1?.image?.filename}`} alt={`${data?.about?.section1?.image?.filename}`} />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="about_text">
                      <h3>About</h3>
                      <img src="/images/retu_signature2.png" alt="" />
                      <h4>{data.about?.section1?.heading}</h4>

                      <p dangerouslySetInnerHTML={{ __html: data?.about?.section1?.text }}  ></p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>

        <div style={{ backgroundImage: `url(${cloudUrl}/${data?.about?.section2?.image?.filename})` }} className="health_section">
          <Container>
            <div className="health_section_text">
              <h2>
                {data?.about?.section2?.heading}
              </h2>

              <p dangerouslySetInnerHTML={{ __html: data?.about?.section2?.text }} >

              </p>

              <button onClick={() => navigate(`/${data?.about?.section2?.link}`)}>GET STARTED</button>
            </div>
          </Container>
        </div>

        <div className="certified_main">
          <Container>
            <h1>CERTIFIED BY</h1>
            <Slider {...settings}>
              {data?.about?.section3 && data?.about?.section3?.map((item, index) => (
                <div key={index} className="d-flex justify-content-center" >
                  <img src={`${cloudUrl}/${item.image.filename}`} alt={`${item.image.filename}`} />
                </div>
              ))}
            </Slider>
          </Container>
        </div>
        <div className="instagram_main">
          <Container>
            <h2>Instagram</h2>
            <Row>
              {data?.about?.section4 && data?.about?.section4?.map((item, index) => {
                return (
                  <Col key={index} md={2} xs={6}>
                    <a href={`${data.about?.section5?.link}`} target="_blank" rel="noreferrer" >
                      <div className="insta_box">
                        <img
                          src={`${cloudUrl}/${item?.image?.filename}`}
                          alt={`${item?.image?.filename}`} />
                        <div>
                          <img src="/images/insta_big_icon.png" alt="" class="instagram_icon"></img>
                        </div>
                      </div>
                    </a>
                  </Col>
                )
              }
              )}
            </Row>
          </Container>
        </div>

        <OtherFooter />
      </> : <div style={{ height: "80vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Loader />
      </div>}
    </React.Fragment>
  );
};

export default About;

