import React from 'react'
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import './Profile.css'
import DefaultImage from '../../../../assets/blog_section1_bg.png'
const cloudUrl = 'https://retu-sukhramani.azurewebsites.net/assets/uploads';
// const cloudUrl = 'http://localhost:5000/assets/uploads';

const Profile = () => {
    return (
        <div className='page_responsive'>
            <h1>Profile</h1>
            <Container className='profile-container' style={{ backgroundColor: "#f6dbe2a6", padding: "20px", marginLeft: 0 }}>
                <h2 className='profile-sub-heading'>Profile Setting</h2>
                <img src={DefaultImage} alt='' style={{ width: "90px", marginLeft: "18px", aspectRatio: "1/1", borderRadius: "50%" }} />
                <Form.Group controlId="formFile" className="m-3">
                    <Form.Control type="file" style={{ fontSize: "13px", padding: "13px", width: "200px" }} />
                </Form.Group>
                <Col md={4} style={{ marginLeft: "18px" }}>
                    <InputGroup className="mb-3">
                        <Form.Control placeholder='Enter Heading Name' name='heading' value={"a"} />
                    </InputGroup>
                </Col>
                <div className={"text-end my-4"}>
                    <Button type='Submit' className="cms_submit_btn">Update Profile </Button>
                </div>
                <hr />
                <h2 className='profile-sub-heading'>Change Password</h2>
                <Row>

                    <Col md={4} className="py-2">
                        <Form.Label>New Password</Form.Label>
                        <InputGroup>
                            <Form.Control type="password" placeholder='Enter your New Password' name="newPassword" value={"132"} />
                        </InputGroup>
                    </Col>

                    <Col md={4} className="py-2">
                        <Form.Label>Confirm Password</Form.Label>
                        <InputGroup>
                            <Form.Control type="password" placeholder='Enter your Confirm Password' name="confirmPassword" value={"132"} />
                        </InputGroup>
                    </Col>
                </Row>
                <div className={"text-end my-4"}>
                    <Button type='Submit' className="cms_submit_btn">Change Password </Button>
                </div>
            </Container>
        </div>
    )
}

export default Profile
