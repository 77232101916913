import React, { useState } from 'react'
import { Button, Container, } from 'react-bootstrap'
import { useGetAdminServicesQuery } from '../../../../api/adminHome';
import Loader from '../../../../Helper/Loader';
import ServiceModal from './ServiceModal.js';
import ServiceData from './ServiceData.js';

const Services = () => {
    const { data, isLoading } = useGetAdminServicesQuery();
    console.log(data);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const serviceModal = <ServiceModal show={show} handleClose={handleClose} />
    return (
        <div className='page_responsive'>
            <h1>Services</h1>
            {(!isLoading && data.service) ?
                <Container style={{ backgroundColor: "#f6dbe2a6", padding: "20px", marginLeft: 0 }}>
                    <div className={"text-end my-4"}>
                        <Button type='Submit' onClick={handleShow} className="cms_submit_btn">Create </Button>
                    </div>
                    {data?.service?.map((item) => {
                        return (
                            <ServiceData service={item} />
                        )
                    })}
                </Container> :
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Loader />
                </div>
            }
            {serviceModal}

        </div>
    )
}

export default Services
