import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { errorNotify, successNotify } from "../../Helper/Toast";
import { useCreateNewsLetterEmailMutation } from "../../api/adminHome";

const OtherFooter = () => {
  const navigate = useNavigate();
  const [createNewsEmail] = useCreateNewsLetterEmailMutation();
  const [email, setEmail] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = async () => {
    // You can perform actions here, such as sending the email to a server, etc.
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email === '') {
      errorNotify('Enter Email');
      return;
    }
    if (!emailRegex.test(email)) {
      errorNotify('Invalid email address');
      return;
    }
    try {
      const res = await createNewsEmail({ email: email });
      if (res?.data?.success) {
        successNotify("News Letter Subscribed Successfully")
      }
      else {
        errorNotify("Something went wrong")
      }
      setEmail("");
    } catch (error) {
      console.error('Failed to subscribe:', error);
    }
    console.log(`Subscribing with email: ${email}`);
  };
  return (
    <div className="footer_details about_page">
      <Container fluid className="p-0">
        <Row className="align-items-center">
          <Col md={12}>
            <div className="footer_left">
              <Container>
                <Row
                  style={{
                    borderBottom: "2px solid #ecefee",
                    paddingBottom: "15px",
                  }}
                >
                  <Col md={8}>
                    <div>
                      <ul>
                        <li onClick={() => navigate("/")}>Home</li>
                        <li onClick={() => navigate("/about")}>About</li>
                        <li onClick={() => navigate("/service/rs-method-1-1")}>
                          The RS Method
                        </li>
                        {/* <li onClick={() => navigate("/testimonials")}>
                          Testimonials
                        </li> */}
                        <li onClick={() => navigate("/blog")}>Blogs</li>
                        <li onClick={() => navigate("/contact-us")}>Contact Us</li>
                        {/* <li onClick={() => navigate("/login")}>Sign In</li> */}
                      </ul>

                      <h2>Let Me Slide In To Your Inbox</h2>
                      <p>
                        Sign up to receive free resources and my thoughts on everything from emotional eating to
                        body image to recipes.
                      </p>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="newsletter_div">
                      <h4>NEWSLETTER SIGN UP</h4>

                      <div className="email_div">
                        <label>Email</label>
                        <input type="email" onChange={handleEmailChange} value={email} />
                      </div>
                      <div>
                        <button onClick={handleSubscribe} className="sub_btn">Subscribe</button>
                      </div>
                    </div>
                  </Col>
                </Row>

                <h5>©Reetu Sukhramani. All right Reversed.</h5>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OtherFooter;
