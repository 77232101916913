import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../Blog/BlogRegular/BlogRegular";
import OtherFooter from "../../../Components/OtherFooter/OtherFooter";
import Header from "../../../Components/Header/Header";
import TestimonialHeader from "../../../Components/Header/TestimonialHeader";
import { Link } from "react-router-dom";
import { useGetSingleBlogQuery } from "../../../api/adminHome";
import Loader from "../../../Helper/Loader";
import { cloudUrl } from "../../../Helper/Helper";

const SingleBlog = () => {
    const currentUrl = window.location.href;
    const id = currentUrl.substring(currentUrl.lastIndexOf('/') + 1);

    const { data, isLoading } = useGetSingleBlogQuery({ id });
    console.log(data, isLoading)
    const [headerShow, setHeaderShow] = useState(true);
    return (
        <>
            {!isLoading && data.blog ? <div className="blog_regular">
                <Container fluid className="p-0">
                    <Row>
                        <TestimonialHeader />
                    </Row>
                    <Row className="mob_nav">
                        <Header
                            headerShow={headerShow}
                            setHeaderShow={setHeaderShow}
                            goToQuote={null}
                            showQuote={false}
                        />
                    </Row>
                </Container>

                <div className="about_main about_page blog_regular_page blog-1" style={{ backgroundImage: `url(${cloudUrl}/${data?.blog?.thumbnail?.filename})` }} >
                    <Container className="SectionOne">
                        <div className="image-container">
                            {/* <img src="/images/BingeEating.png" alt="" /> */}
                            <div>
                                <h1>
                                    {data?.blog?.heading}
                                </h1>
                                <hr />
                                <div>
                                    BY REETU SUKHRAMANI
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>

                <Container fluid className="SectionTwo">
                    <Row className="justify-content-center">
                        <Col md={11} className="Main">
                            <div className="Para">
                                <p dangerouslySetInnerHTML={{ __html: data?.blog?.text }}></p>
                                <button className="blog_btn"> <Link to={`${data?.blog?.link}`} target="_blank">
                                    ENROLL NOW
                                </Link>
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <OtherFooter />
            </div> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Loader />
            </div>}
        </>

    );
};

export default SingleBlog;
