import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Slider from 'react-slick';
import { cloudUrl, feedbackSettings } from '../../Helper/Helper';
import { useGetAdminTestimonialQuery } from '../../api/adminHome';
import Loader from '../../Helper/Loader';

const FeedbackPage = () => {
    const { data, isLoading } = useGetAdminTestimonialQuery();
    return (
        <div className="testimonial_main">
            <Container>
                <Row className="justify-content-center">
                    <Col md={10}>
                        <div>

                            <div className="testimonial_comas">
                                <img src="/images/testimonial_comas.png" alt="" />

                                {/* <div></div> */}
                                <div className="testimonial_head">
                                    <h2>CLIENT TESTIMONIALS</h2>
                                </div>
                            </div>

                            <div className="client_testimonials_main">
                                {(!isLoading && data?.testimonial) ?
                                    <Slider {...feedbackSettings}>
                                        {data?.testimonial?.map((item, index) => {
                                            return (
                                                <div key={item._id} className="client_testimonials">
                                                    <Row className="justify-content-end">
                                                        <Col md={6}>
                                                            <div className="testimonial_img">
                                                                <img src={`${cloudUrl}/${item?.image?.filename}`}
                                                                    alt={`${item?.image?.filename}`} />
                                                            </div>
                                                        </Col>
                                                        <Col md={8}>
                                                            <div className="testimonial_show">
                                                                <div style={{ color: "white !important" }} dangerouslySetInnerHTML={{ __html: item?.text }} ></div>
                                                                <p style={{ fontWeight: "600" }}>{item.createdBy}</p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                        })}



                                    </Slider> : <div style={{ height: "80vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Loader />
                                    </div>}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default FeedbackPage
