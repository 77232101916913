import { toast } from "react-toastify";

const notify = (message, type) => {
    toast[type](message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
};

export const successNotify = (message) => notify(message, "success");

export const errorNotify = (message) => notify(message, "error");

export const infoNotify = (message) => notify(message, "info");

export const warningNotify = (message) => notify(message, "warning");
