import React from 'react';
import { Accordion, Col, Container, Row } from 'react-bootstrap';
import './Faqs.css';

const Faqs = ({ data }) => {
    return (
        <div className='faqs_main'>
            <Container>
                <h1>FAQS</h1>
                <h2 className='mb-4'>Most asked questions</h2>

                <Row className='justify-content-center'>
                    <Col md={10}>
                        <Accordion defaultActiveKey="0">
                            {data.map((item, index) => {
                                return (
                                    <Accordion.Item eventKey={index}>
                                        <Accordion.Header>{item.question}</Accordion.Header>
                                        <Accordion.Body>
                                            <ul dangerouslySetInnerHTML={{ __html: item.answer }}></ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )
                            })}
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Faqs
