import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import TestimonialHeader from "../../../Components/Header/TestimonialHeader";
import './Contact.css';
import OtherFooter from "../../../Components/OtherFooter/OtherFooter";

const Contact = () => {
    return (
        <React.Fragment>
            <Container fluid>
                <Row>
                    <TestimonialHeader />
                </Row>
            </Container>
            <div className="auth_main contact_page">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="auth_box" style={{ maxWidth: "initial" }}>
                                <Row>
                                    <Col md={6}>
                                        <h2>Reetu Sukhramani</h2>
                                        <h3>Contact Us</h3>
                                        <Form>
                                            <Row>
                                                <Col md={6}>
                                                    <Form.Group className="mb-2">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="First Name"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group className="mb-2">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Last Name"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Group className="mb-2">
                                                        <Form.Control
                                                            type="email"
                                                            placeholder="Email"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Group className="mb-2">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Phone Number"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Group className="mb-2">
                                                        <Form.Control
                                                            as="textarea" rows={5}
                                                            type="text"
                                                            placeholder="Message"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <button className="submit_btn" type="submit">
                                                        Submit
                                                    </button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>

                                    <Col md={6}>
                                        <div className="work_location">
                                            <h3>Work Location</h3>

                                            <p style={{ color: "#5a5555" }}>Tamani Arts Building,
                                                Office number: 1711,
                                                Business Bay <br />
                                                Dubai, UAE
                                            </p>

                                            <iframe src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d3610.4721034274503!2d55.278456!3d25.1872968!3m2!1i1024!2i768!4f13.1!3m2!1m1!2s!5e0!3m2!1sen!2s!4v1695901702943!5m2!1sen!2s"
                                                width="100%" height="300" style={{ border: "0", borderRadius: "40px" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        {/* <Col md={6}>
                            <div className="work_location">
                                <h3>WORK LOCATION</h3>

                                <p style={{ color: "#5a5555" }}>Tamani Arts Building,
                                    Office number: 1711,
                                    Business Bay <br />
                                    Dubai, UAE
                                </p>

                                <iframe src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d3610.4721034274503!2d55.278456!3d25.1872968!3m2!1i1024!2i768!4f13.1!3m2!1m1!2s!5e0!3m2!1sen!2s!4v1695901702943!5m2!1sen!2s"
                                    width="600" height="300" style={{ border: "0", borderRadius: "40px" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </Col> */}
                    </Row>
                </Container>
            </div>

            <OtherFooter />
        </React.Fragment>
    );
};
export default Contact;