import React from 'react'
import { Form, Button, Col, Row, Spinner, InputGroup } from 'react-bootstrap'
import DefaultImage from '../../../../../../assets/signature.png'
import { MdDelete } from "react-icons/md";
import { useState } from 'react';
import ReactQuill from 'react-quill';
import SiteModal from '../../../../../../Components/SiteModal/SiteModal.js';
import { useGetAdminAboutQuery } from '../../../../../../api/adminHome.js';
import { errorNotify, successNotify } from '../../../../../../Helper/Toast.js';
import { useEffect } from 'react';
import { cloudUrl } from '../../../../../../Helper/Helper.js';

const modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
        [{ list: "ordered" }, { list: "bullet" }]
    ]
};
const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "color",
    "background"
]

const Section4 = ({ data, onUpdate, updateRes, section5data }) => {

    console.log(section5data);

    console.log(updateRes, "updateRes");
    const [isLoading, setIsLoading] = useState(false);


    const [sectionData, setSectionData] = useState(data);
    const [show, setShow] = useState(false);
    const [imagePreviews, setImagePreviews] = useState([]);
    const [images, setImages] = useState([]);
    const [addImage, setAddImage] = useState();
    const [addShow, setAddShow] = useState(false);
    const [addImagePreview, setAddImagePreview] = useState();

    console.log(sectionData, "sectionData");


    const handleAdd = (e) => {
        e.preventDefault();
        if (e.target.name === 'Cancel') {
            if (addImagePreview) {
                setImages(prevImages => {
                    const newImages = [...prevImages];
                    newImages.pop();
                    return newImages;
                });
            }
        }
        setAddShow(!addShow);
        setSectionData([...sectionData])
    }

    const handleSectionUpdate = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (images.length !== 0) {
            setSectionData([]);
            setAddShow(false);
            onUpdate({ e, section: 4, sectionData, images, addImage });
            successNotify("Updated Section 4 ")
        } else {
            errorNotify("No Image is changes");
            setIsLoading(false);
        }

    }


    const deleteHandler = () => {

    }
    const deleteModalHandler = (e) => {
        e.preventDefault();
        setShow(!show)
    }
    const imageHandler = (e, index) => {
        const file = e.target.files[0];
        setImages(prevImages => {
            const newImages = [...prevImages];
            newImages[index] = file;
            return newImages;
        });
        setSectionData(prev => {
            return prev.map((item, i) => {
                if (i === index) {
                    return {
                        ...item,
                        image: {
                            ...item.image,
                            isFile: 1,
                        },
                    };
                }
                return item;
            });
        });
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setImagePreviews(prevImages => {
                    const newImages = [...prevImages];
                    newImages[index] = event.target.result;
                    return newImages;
                });
            };
            reader.readAsDataURL(file);
        }
    }
    const addImageChangeHandler = (e) => {
        const file = e.target.files[0];
        if (addShow) {
            setImages(prevImages => {
                const newImages = [...prevImages, file];
                // newImages[index] = file;
                return newImages;
            });
        }
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setAddImagePreview(event.target.result);
            };
            reader.readAsDataURL(file);
        }
    }
    const deleteLoading = false;
    const modal = <SiteModal title="Delete" size={"lg"} show={show} showIcon={true} onCloseModal={() => setShow(!show)}>
        <div className='delete_modal'>
            <h5>Are you sure you want to delete this??</h5>

            <div>
                <button onClick={deleteHandler}>{deleteLoading ? <Spinner animation='border' size='sm' /> : 'Yes'}</button>
                <button onClick={() => setShow(!show)}>No</button>
            </div>
        </div>
    </SiteModal>

    const newSectionDataItem = <Col key={''} md={4}>
        <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Images</Form.Label>
            <Form.Control type="file" style={{ fontSize: "13px", paddingBottom: "7px" }} onChange={addImageChangeHandler} />
            <div style={{ width: "50%", aspectRatio: "1/1", display: "flex", justifyContent: "center", alignItems: "center", border: "1px solid black", borderRadius: "30px" }} >
                <img src={addImagePreview ? addImagePreview : DefaultImage} alt={`DefaultImage`} style={{ width: "120px" }} />
            </div>
        </Form.Group>
    </Col>
    return (
        <div>
            {modal}
            <Form>
                <Row className='home-section-container'>
                    <div className={"text-end my-4"}>
                        <Button type='Submit' className="cms_submit_btn" name={`${addShow ? "Cancel" : "Add"}`} onClick={handleAdd} >{`${addShow ? "Cancel" : "Add"}`}</Button>
                    </div>
                    {sectionData.length !== 0 ? sectionData.map((item, index) => {
                        return (
                            <Col key={item._id} md={4}>
                                <Form.Group controlId="formFile" className="mb-3">
                                    <Form.Label>Images{`${index + 1}`}</Form.Label>
                                    <Form.Control type="file" style={{ fontSize: "13px", paddingBottom: "7px" }} onChange={(e) => imageHandler(e, index)} />
                                    <button onClick={deleteModalHandler} className='section_delete_btn' ><MdDelete /></button>
                                    <div style={{ marginTop: "15px", width: "50%", aspectRatio: "1/1", display: "flex", justifyContent: "center", alignItems: "center", border: "1px solid black", borderRadius: "30px" }} >
                                        <img src={imagePreviews[index] ? imagePreviews[index] : `${cloudUrl}/${item.image.filename}`} alt={`${item.image.filename}`} style={{ width: "120px" }} />
                                    </div>

                                </Form.Group>
                            </Col>
                        )
                    }) : null}
                    {addShow ? newSectionDataItem : null}



                    <div className={"text-end my-4"}>
                        <Button disabled={isLoading ? true : false} onClick={handleSectionUpdate} type='Submit' className="cms_submit_btn">{isLoading ? <Spinner animation='border' size='sm' /> : "Submit"} </Button>
                    </div>

                </Row>

            </Form>
        </div>
    )
}

export default Section4
/*

<Row>
                    <Col md={9} >
                        <Form.Label>Instagram Link</Form.Label>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon3">
                                https://
                            </InputGroup.Text>
                            <Form.Control id="basic-url" aria-describedby="basic-addon3" name='url' value={section5Data} onChange={(e) => setSection5Data(e.target.value)} />
                        </InputGroup>

                    </Col>
                    <Col md={3} style={{ marginTop: "23px" }} >
                        <Button onClick={handleSection5Update} type='Submit' className="cms_submit_btn">Update Link </Button>
                    </Col>
                </Row>


                 const handleSection5Update = (e) => {

        e.preventDefault();
        setIsLoading(true);
        setSection5Data("");
        onUpdate({ e, section: 5, sectionData: section5Data });
        successNotify("Updated Section 4 ")

    const [section5Data, setSection5Data] = useState(section5data.link);

    useEffect(() => {
        if (updateRes !== null) {
            setSectionData(data);
            setSection5Data(section5data.link)
            setIsLoading(false);
        }
    }, [updateRes]);


    }
 */