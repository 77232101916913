import React, { useEffect } from 'react'
import { Form, Button, Col, Row, Spinner, InputGroup } from 'react-bootstrap'

import { useState } from 'react';
import ReactQuill from 'react-quill';
import { cloudUrl, modules, formats } from '../../../../../../Helper/Helper';
import { successNotify } from '../../../../../../Helper/Toast.js';




const Section1 = ({ data, onChange, updateRes },) => {
  const [imagePreview, setImagePreview] = useState('');

  const [sectionData, setSectionData] = useState({
    heading: data.heading,
    text: data.text,
    url: data.link,
    image: data.image.filename
  })
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    if (updateRes) {
      setSectionData({
        heading: updateRes?.data?.home?.section1?.heading,
        text: updateRes?.data?.home?.section1?.text,
        url: updateRes?.data?.home?.section1?.link,
        image: updateRes?.data?.home?.section1?.image?.filename
      })
      setIsLoading(false);

    }
  }, [updateRes]);
  const [image, setImage] = useState()
  const imageHandler = (e) => {
    const file = e.target.files[0];
    setImage(file)
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setImagePreview(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  }
  const onDataChange = (e, name) => {

    setSectionData((prev) => { return { ...prev, [name]: e.target.value } })

  }
  const onTextChange = (value) => {
    setSectionData((prev) => { return { ...prev, text: value } })

  }
  const handleSectionUpdate = (e) => {
    setIsLoading(true);
    onChange({ e, section: 1, sectionData: { ...sectionData, image } })
    setSectionData({
      heading: "",
      text: "",
      url: "",
      image: ""
    });
    setImage('');
    setImagePreview('');
    successNotify("Home Section 1 Updated")
  }

  return (
    <div>
      <Form onSubmit={handleSectionUpdate}>
        <Row>
          <Col md={12}>
            <Form.Label>Main Heading</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control placeholder='Enter Heading Name' name='heading' value={sectionData?.heading} onChange={(e) => onDataChange(e, "heading")} />
            </InputGroup>
          </Col>
          <Col md={12}>
            <Form.Label>Text</Form.Label>
            <ReactQuill style={{ backgroundColor: "white" }} name="text" className="mb-3" theme="snow" modules={modules} formats={formats} value={sectionData?.text} onChange={onTextChange} />
          </Col>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon3">
              https://
            </InputGroup.Text>
            <Form.Control id="basic-url" aria-describedby="basic-addon3" name='url' value={sectionData?.url} onChange={(e) => onDataChange(e, "url")} />
          </InputGroup>
          <Col md={12}>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Image</Form.Label>
              <Form.Control type="file" style={{ fontSize: "13px", paddingBottom: "7px" }} onChange={imageHandler} />
            </Form.Group>
            <img src={imagePreview ? imagePreview : `${cloudUrl}/${sectionData?.image}`} alt={`${sectionData?.image}`} style={{ width: "120px" }} />
          </Col>
        </Row>
        <hr />
        <div className={"text-end my-4"}>
          <Button type='Submit' className="cms_submit_btn">{isLoading ? <Spinner animation='border' size='sm' /> : 'Submit'} </Button>
        </div>
      </Form>
    </div>
  )
}

export default Section1
