import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import { Navbar, NavDropdown } from "react-bootstrap";
import "./Header.css";
import { useNavigate } from "react-router-dom";
import defaultImage from '../../../assets/retu_banner_img3_2.png'

const Header = () => {
  const navbarRef = useRef(null);
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (navbarRef.current) {
      navbarRef.current.addEventListener("hidden.bs.collapse", () => {
        setExpanded(false);
      });
      navbarRef.current.addEventListener("show.bs.collapse", () => {
        setExpanded(true);
      });
    }
  }, []);

  const handleLinkClick = () => {
    if (navbarRef.current) {
      navbarRef.current.classList.remove("show");
      setExpanded(false);
    }
  };

  const dashboardHandler = () => {
    navigate("/admin/dashboard");
  };

  const LogoutHandler = () => {
    navigate("/");
    localStorage.removeItem("token");
  };

  let showNav = (
    // <div className="d-flex align-items-center">
    //   {loading ? null : (
    <Navbar id="basic-navbar-nav" ref={navbarRef}>
      <div className="d-flex align-items-center justify-content-center login_nav" >
        <img
          src={defaultImage}
          alt="asdf"
          style={{
            width: "55px",
            height: "55px",
            objectFit: "cover",
            borderRadius: "100px",
            marginRight: "10px",
          }}
        />
        <NavDropdown title="Admin" id="basic-nav-dropdown">
          <NavDropdown.Item onClick={dashboardHandler}>
            Dashboard
          </NavDropdown.Item>
          <NavDropdown.Item onClick={() => navigate("/")}>
            Go to Website
          </NavDropdown.Item>
          <NavDropdown.Item onClick={LogoutHandler}>Logout</NavDropdown.Item>
        </NavDropdown>
      </div>
    </Navbar>
  );
  //   )}
  // </div>

  return (
    <React.Fragment>

      <Navbar expand="lg" collapseOnSelect={true} expanded={expanded}>
        <Container>
          {showNav}
        </Container>
      </Navbar>
    </React.Fragment>
  );
};
export default Header;
