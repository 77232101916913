import React from 'react'
import { Button, Col, Container, } from 'react-bootstrap'
import { useState } from 'react';

import { useCreateAdminBlogMutation, useGetAdminBlogQuery } from '../../../../api/adminHome';
import Loader from '../../../../Helper/Loader';
import './Blog.css'
import BlogModal from './BlogModal';
import { errorNotify, successNotify } from '../../../../Helper/Toast';
import BlogData from './BlogData';



const Blogs = () => {
    const [createBlog] = useCreateAdminBlogMutation();
    const handleClose = () => setShow(false);
    const [isBlogLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const { data, isLoading } = useGetAdminBlogQuery();
    const [blogPageData, setBlogPageData] = useState([]);

    if (blogPageData.length === 0 && !isLoading && data.blogs.length > 0) {
        setBlogPageData(data.blogs);
    }
    const handleShow = (isAdd, item = null) => {
        setShow(true);
    }

    const handleBlogCreate = async (e, blogData, image, thumbnail) => {

        e.preventDefault();
        if (blogData.heading === '' && blogData.text === '' && image === '' && thumbnail === '') {
            errorNotify("Blog is empty enter some feilds")
        }
        else {
            setIsLoading(true);
            const formData = new FormData();
            formData.append('heading', blogData.heading);
            formData.append('text', blogData.text);
            formData.append('link', blogData.link);
            formData.append('image', image);
            formData.append('thumbnail', thumbnail);
            const res = await createBlog(formData);
            if (res.data) {
                setBlogPageData(prev => {
                    const newBlogData = [...prev, res.data.blog];
                    return newBlogData;
                })
                successNotify('Blog Created Successfully')
            }
            else {
                errorNotify("Something went wrong")
            }
            setIsLoading(false);
            handleClose();
        }
    }






    const blogModal = <BlogModal show={show} handleClose={handleClose} handleBlogCreate={handleBlogCreate} isLoading={isBlogLoading} />

    return (
        <div className='page_responsive'>
            <h1>Blogs</h1>
            {(!isLoading && data.blogs) ?
                <Container style={{ backgroundColor: "#f6dbe2a6", padding: "20px", marginLeft: 0 }}>
                    <div className={"text-end my-4"}>
                        <Button type='Submit' onClick={() => handleShow(true)} className="cms_submit_btn">Create </Button>
                    </div>
                    {blogPageData?.map((item) => {
                        return (

                            <BlogData blog={item} />

                        )
                    })}
                </Container> :
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Loader />
                </div>
            }
            {blogModal}

        </div>
    )
}

export default Blogs


