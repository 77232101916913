import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../Blog/BlogRegular/BlogRegular";
import OtherFooter from "../../../Components/OtherFooter/OtherFooter";
import Header from "../../../Components/Header/Header";
import TestimonialHeader from "../../../Components/Header/TestimonialHeader";
import { Link } from "react-router-dom";

const Blog4 = () => {
  const [headerShow, setHeaderShow] = useState(true);
  return (
    <div className="blog_regular">
      <Container fluid className="p-0">
        <Row>
          <TestimonialHeader />
        </Row>
        <Row className="mob_nav">
          <Header
            headerShow={headerShow}
            setHeaderShow={setHeaderShow}
            goToQuote={null}
            showQuote={false}
          />
        </Row>
      </Container>

      <div className="about_main about_page blog_regular_page blog-4">
        <Container className="SectionOne">
          <div className="image-container">
            {/* <img src="/images/BingeEating.png" alt="" /> */}
            <div>
              <h1>
                Emotional Eating <br />
                Do you emotional eat?
              </h1>
              <hr />
              <div>
                BY REETU SUKHRAMANI
              </div>
            </div>
          </div>
        </Container>
      </div>

      <Container fluid className="SectionTwo">
        <Row className="justify-content-center">
          <Col md={11} className="Main">
            <div className="Para">

              <h3>Then ask yourself these questions:</h3>

              <ul className="SupportPeople">
                <li>What aspect of my life am I currently unable to accept?</li>
                <li>What emotion do I not want to feel right now? (Google Wheel of Emotions)</li>
                <li>Are my essential needs being met? (Google Maslow’s Hierarchy of Needs)</li>
                <li>Can I wait for five minutes to breathe before deciding?</li>
                <li>What would my future self want me to do right now?</li>
                <li>Is there any other way I can express this feeling?</li>
              </ul>

              <p>Disclaimer: Emotional eating isn't necessarily a terrible thing, but if it's your only way to cope with your emotions, we need to discover other ways to regulate our emotions.  </p>

              <p>If you're interested in developing a toolkit to help you regulate your emotions, fill out the form below and we'll talk.</p>

              <button className="blog_btn"> <Link to={"https://docs.google.com/forms/d/17P7hXZXLOLouePep2OEJg_pR0jjrd0OE775lQ3PE65Q/edit"} target="_blank">
                ENROLL NOW
              </Link>
              </button>
            </div>
          </Col>
        </Row>
      </Container>

      <OtherFooter />
    </div>
  );
};

export default Blog4;
