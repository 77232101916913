import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Container/Pages/Home/Home";
import About from "./Container/Pages/About/About";
import RsMethod from "./Container/Pages/Services/RsMethod/RsMethod";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Contact from "./Container/Pages/Contact/Contact";
import Register from "./Container/Pages/Auth/Register/Register";
import Login from "./Container/Pages/Auth/Login/Login";
import Blog from "./Container/Pages/Blog/Blog";
import Blog2 from "./Container/Pages/Blog/Blog2";
import Blog3 from "./Container/Pages/Blog/Blog3";
import Blog4 from "./Container/Pages/Blog/Blog4";
import Blog1 from "./Container/Pages/Blog/Blog1";
import SingleBlog from './Container/Pages/SingleBlog/SingleBlog.js';
import AdminLayout from "./Container/protectedRoutes/AdminLayout";
import { AdminRoutes } from "./Container/Admin/Routes/Routes";
import { useGetUserQuery } from "./api/adminHome";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
const App = () => {
  useGetUserQuery();
  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      AOS.init();
    }, [])
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return null;
  }

  const adminLayout = (
    <Route path={"/admin"} element={<AdminLayout />}>
      {AdminRoutes.map((item) => (
        <Route key={item.path} path={item.path} element={item.component} />
      ))}
    </Route>
  );

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light" />
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/service/:id" element={<RsMethod />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<SingleBlog />} />
          <Route path="/blog/1" element={<Blog1 />} />
          <Route path="/blog/2" element={<Blog2 />} />
          <Route path="/blog/3" element={<Blog3 />} />
          <Route path="/blog/4" element={<Blog4 />} />
          <Route path='/admin/login' element={<Login />} />
          <Route path="/register" element={<Register />} />
          {adminLayout}
        </Routes>
      </BrowserRouter>
    </>
  );
};
export default App;