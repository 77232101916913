import { createSlice } from '@reduxjs/toolkit'

export const homeSlice = createSlice({
    name: 'home',
    initialState: {
        loading: false,
        error: false,
        data: {}
    },
    reducers: {
        getHomePending: (state) => {
            state.loading = true;
            state.error = false;
        },
        getHomeSuccess: (state, action) => {
            state.loading = false;
            state.error = false;
            state.data = action.payload;
        },
        getHomeFailed: (state, action) => {
            state.loading = false;
            state.error = true;
        },
        clearHome: (state) => {
            state.loading = false;
            state.error = false;
            state.data = {}
        }
    },
})

export const { getHomePending, getHomeSuccess, getHomeFailed, clearHome } = homeSlice.actions

export default homeSlice.reducer