import React, { useEffect, useState } from 'react'
import { Button, Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap'
import { cloudUrl } from '../../../../Helper/Helper'
import ReactQuill from 'react-quill'
import { useDeleteAdminBlogMutation, useUpdateAdminBlogMutation, useUpdateAdminMainBlogMutation } from '../../../../api/adminHome';
import { errorNotify, successNotify } from '../../../../Helper/Toast';

const modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
        [{ list: "ordered" }, { list: "bullet" }]
    ]
};
const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "color",
    "background"
]

const BlogData = ({ blog }) => {

    const [item, setItem] = useState();

    const [image, setImage] = useState('');
    const [imagePreview, setImagePreview] = useState();

    const [thumbnail, setThumbnail] = useState('');
    const [thumbnailPreview, setThumbnailPreview] = useState();

    const [isDeleted, setIsDeleted] = useState(false);
    const [editBlog] = useUpdateAdminBlogMutation();
    const [deleteBlog] = useDeleteAdminBlogMutation();
    const [updateMainBlog] = useUpdateAdminMainBlogMutation();



    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);


    useEffect(() => {
        setItem(blog)
    }, [blog])
    const handleDataChange = (e, name) => {
        setItem((prev) => { return { ...prev, [name]: e.target.value } })
    }

    const onTextChange = (value) => {
        setItem((prev) => { return { ...prev, text: value } })
    };
    const imageHandler = (e, flag) => {
        const file = e.target.files[0];
        if (flag === 'image') {
            setImage(file)
            if (file) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    setImagePreview(event.target.result);
                };
                reader.readAsDataURL(file);
            }
        }
        else if (flag === 'thumbnail') {
            setThumbnail(file)
            if (file) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    setThumbnailPreview(event.target.result);
                };
                reader.readAsDataURL(file);
            }
        }
    }
    const handleBlogEdit = async (e) => {
        e.preventDefault();
        if (item.heading === '' && item.text === '' && image === '' && thumbnail === '') {
            errorNotify("Blog is empty enter some feilds")
        }
        else {
            setIsLoading(true);
            const formData = new FormData();
            formData.append('heading', item.heading);
            formData.append('text', item.text);
            formData.append('link', item.link);
            formData.append('trending', item.trending);
            if (image) {
                formData.append('image', image);
            }
            if (thumbnail) {
                formData.append('thumbnail', thumbnail);
            }
            console.log(formData, "formData")
            console.log(item._id);
            const res = await editBlog({ id: item._id, formData });
            console.log(res);
            if (res.data) {
                console.log(res.data);
                successNotify('Blog Updated Successfully')
            }
            else {
                errorNotify("Something went wrong")
            }
            setIsLoading(false);
        }
    }
    const handleChangeMainBlog = async (e) => {
        e.preventDefault();
        const res = await updateMainBlog({ id: item._id });
        if (res.data) {
            successNotify('This Blog is Set as Main')
        }
        else {
            errorNotify("Something went wrong")
        }
    }
    const handleBlogDelete = async (e) => {
        e.preventDefault();
        setIsDeleteLoading(true);
        const res = await deleteBlog({ id: item._id });

        if (res.data) {
            successNotify('Blog Deleted Successfully')
        }
        else {
            errorNotify("Something went wrong")
        }
        setIsDeleteLoading(false);
        setIsDeleted(true);
    }
    const handleCheckboxChange = () => {
        setItem((prev) => { return { ...prev, ['trending']: !item?.trending } })
    }
    return (
        <>

            {!isDeleted ? <Col md={12}>
                <h4 style={{ backgroundColor: "#657f76", padding: "10px", borderRadius: "15px", color: "whitesmoke" }} >Blog </h4>
                <Form.Group controlId="formFile" className="mb-3">
                    <Row>
                        <Col md={12} >
                            <Form.Label style={{
                                fontWeight: "bold", marginLeft: "5px", marginTop: "15px"
                            }} >Heading</Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control placeholder='Enter Heading Name' name='heading' value={item?.heading} onChange={(e) => handleDataChange(e, 'heading')} />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} >
                            <Form.Label style={{ fontWeight: "bold", marginLeft: "5px", marginTop: "15px" }}>Image</Form.Label>
                            <Form.Control type="file" style={{ fontSize: "13px", paddingBottom: "7px" }} onChange={(e) => imageHandler(e, 'image')} />
                            <img src={imagePreview ? imagePreview : `${cloudUrl}/${item?.image?.filename}`} alt={`${item?.image?.filename}`} style={{ width: "120px" }} />
                        </Col>
                        <Col md={6} >
                            <Form.Label style={{ fontWeight: "bold", marginLeft: "5px", marginTop: "15px" }}>Thumbnail</Form.Label>
                            <Form.Control type="file" style={{ fontSize: "13px", paddingBottom: "7px" }} onChange={(e) => imageHandler(e, 'thumbnail')} />
                            <img src={thumbnailPreview ? thumbnailPreview : `${cloudUrl}/${item?.thumbnail?.filename}`} alt={`${item?.thumbnail?.filename}`} style={{ width: "120px" }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} >
                            <Form.Label style={{ fontWeight: "bold", marginLeft: "5px", marginTop: "15px" }}>Text</Form.Label>
                            <ReactQuill style={{ backgroundColor: "white" }} name="text" className="mb-3" theme="snow" modules={modules} formats={formats} value={item?.text}
                                onChange={onTextChange} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} >
                            <Form.Label style={{ fontWeight: "bold", marginLeft: "5px", marginTop: "15px" }}>Trending Blog</Form.Label>
                            <div style={{ width: "100%", padding: "10px" }} >
                                <Form.Check
                                    type={'checkbox'}
                                    id={`checkBox`}
                                    className='blogData-switch'
                                    checked={item?.trending}
                                    onChange={handleCheckboxChange}
                                />
                            </div>
                        </Col>
                        <Col md={4} >
                            <div style={{ display: "flex", justifyContent: 'center', alignItems: "center", height: "100%" }}>
                                <Button onClick={handleChangeMainBlog} className="cms_submit_btn" type='Submit'>Set This to Main Blog</Button>
                            </div>
                        </Col>
                        <Col md={4} >
                            <Form.Label style={{ fontWeight: "bold", marginLeft: "5px", marginTop: "15px" }}>Link</Form.Label>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon3">
                                    https://
                                </InputGroup.Text>
                                <Form.Control id="basic-url" aria-describedby="basic-addon3" name='link' value={item?.link} onChange={(e) => handleDataChange(e, 'link')} />
                            </InputGroup>
                        </Col>
                    </Row>

                    <div className={"text-end my-4"}>
                        <Button type='Submit'
                            disabled={isLoading || isDeleteLoading ? true : false}
                            onClick={handleBlogEdit}
                            className="cms_submit_btn">{isLoading ? <Spinner animation='border' size='sm' /> : "Edit"}</Button>
                        <Button type='Submit'
                            onClick={handleBlogDelete}
                            disabled={isLoading || isDeleteLoading ? true : false}
                            className="cms_submit_btn">{isDeleteLoading ? <Spinner animation='border' size='sm' /> : 'Delete'}</Button>
                    </div>
                </Form.Group></Col> : null}

        </>
    )
}

export default BlogData
