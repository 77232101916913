import { configureStore } from '@reduxjs/toolkit'
import homeReducer from './feature/homeSlice'
import { adminHomeApi } from './api/adminHome'
export default configureStore({
    reducer: {
        home: homeReducer,
        [adminHomeApi.reducerPath]: adminHomeApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            adminHomeApi.middleware,
        )
})