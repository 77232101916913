import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../Blog/BlogRegular/BlogRegular";
import OtherFooter from "../../../Components/OtherFooter/OtherFooter";
import Header from "../../../Components/Header/Header";
import TestimonialHeader from "../../../Components/Header/TestimonialHeader";
import { Link } from "react-router-dom";

const Blog3 = () => {
  const [headerShow, setHeaderShow] = useState(true);
  return (
    <div className="blog_regular">
      <Container fluid className="p-0">
        <Row>
          <TestimonialHeader />
        </Row>
        <Row className="mob_nav">
          <Header
            headerShow={headerShow}
            setHeaderShow={setHeaderShow}
            goToQuote={null}
            showQuote={false}
          />
        </Row>
      </Container>

      <div className="about_main about_page blog_regular_page blog-3">
        <Container className="SectionOne">
          <div className="image-container">
            {/* <img src="/images/BingeEating.png" alt="" /> */}
            <div>
              <h1>
                Why is being ‘thin’ the Roman empire for most women?
              </h1>
              <hr />
              <div>
                BY REETU SUKHRAMANI
              </div>
            </div>
          </div>
        </Container>
      </div>

      <Container fluid className="SectionTwo">
        <Row className="justify-content-center">
          <Col md={11} className="Main">
            <div className="Para">
              <p>
                We think about it.
              </p>

              <h3>We think about it. A lot.</h3>
              <p>
                And it shows up in almost all of our talks as judgments or opinions about ourselves and others. Whether it's about clothes, food, dating, confidence, or success; it's always present in our minds or on the tip of our tongues.               </p>

              <p>
                But why is it so important to us to be thin? And since when did being thin become the only maker of success?               </p>
              <p>
                Now, being thin or wanting to lose weight isn’t a “bad” thing.               </p>

              <p>
                Especially if it's for health reasons, done in a sustainable manner, linked with healthy behaviors, and your present happiness, prosperity, and way of life aren't entirely dependent on this one thing, and your relationship with yourself, body and food isn’t unhealthy.               </p>

              <h3>But, if you believe that being thin is: </h3>

              <ul className="SupportPeople">
                <li>The only way you will be happy,</li>
                <li>The only way you will feel confident</li>
                <li>The only way you will ever put yourself out there</li>
                <li>The only way you will enjoy a slice of cake without any guilt or shame</li>
                <li>The only way you will accept yourself</li>
              </ul>

              <p>Then we have to question it and these deep-rooted, diet culture-y beliefs and mindset about wanting to be thin. </p>

              <p>If you think you are ready to work on this then fill in the form below and let’s chat?</p>

              <button className="blog_btn"> <Link to={"https://docs.google.com/forms/d/17P7hXZXLOLouePep2OEJg_pR0jjrd0OE775lQ3PE65Q/edit"} target="_blank">
                ENROLL NOW
              </Link>
              </button>
            </div>
          </Col>
        </Row>
      </Container>

      <OtherFooter />
    </div>
  );
};

export default Blog3;
