import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";
import "./Home.css";
import FeedbackPage from "../../../Components/Feedback/Feedback";
import { Link, useNavigate } from "react-router-dom";
import { scrollToDiv } from "../../../Helper/Helper";
import OurBlog from "../../../Components/Blogs/OurBlog";
import { useGetAdminHomeQuery, useGetAdminServicesQuery } from "../../../api/adminHome";
import Loader from '../../../Helper/Loader'
import { cloudUrl } from "../../../Helper/Helper";
import signature from '../../../assets/retu_signature1 copy.png'
import serviceBox2 from '../../../assets/servicebox2.png';
import serviceBox3 from '../../../assets/servicebox3.png';


const Home = () => {

  const { data, isLoading } = useGetAdminHomeQuery();
  const { data: servicesData, isLoading: servicesDataLoading } = useGetAdminServicesQuery();
  const [headerShow, setHeaderShow] = useState(false);
  const navigate = useNavigate();
  return (

    <React.Fragment>
      {(!isLoading && data?.home) ? <>
        <div className="home_banner">
          <Container fluid className="p-0">
            <Row>
              <Col
                md={headerShow ? 1 : 3}
                className="p-0"
                style={{ transition: "0.5s" }}
              >
                <Header
                  headerShow={headerShow}
                  setHeaderShow={setHeaderShow}
                  goToQuote={() => scrollToDiv("quote")}
                  showQuote={true}
                />
              </Col>

              <Col
                md={headerShow ? 6 : 4}
                className="p-0"
                style={{ transition: "0.5s" }}
              >
                <div className="home_banner_center">
                  <div className="home_banner_inner">
                    <img src={signature} alt="signature" />

                    <h2>
                      {data?.home?.section1?.heading}
                    </h2>
                    <p dangerouslySetInnerHTML={{ __html: data?.home?.section1?.text }}></p>
                    <button onClick={() => navigate(`/${data?.home?.section1?.link}`)}>
                      Get Started
                    </button>
                  </div>
                </div>
              </Col>

              <Col
                md={headerShow ? 5 : 5}
                className="p-0"
                style={{ transition: "0.5s" }}
              >
                <div>
                  <img src={`${cloudUrl}/${data?.home.section1.image.filename}`} alt="" className="reetu_banner_img" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="quote_banner" id="quote">
          <Container>
            <Row className="justify-content-center">
              <Col md={10}>
                <Row className="align-items-center">
                  <Col md={4}>
                    <div>
                      <img src={`${cloudUrl}/${data?.home.section2.image.filename}`} alt="" />
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className="quote_text">
                      <h1 dangerouslySetInnerHTML={{ __html: data?.home.section2?.text }}></h1>

                      <img src="/images/quote_right_img.png" alt="" />

                      <div className="d-flex align-items-center quote_div">
                        <Link to={data?.home.section2.link} target="_blank">
                          <img src="/images/insta_icon.png" alt="" />
                        </Link>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="about_main">
          <Container>
            <Row className="justify-content-center">
              <Col md={11}>
                <Row className="justify-content-center">
                  <Col md={6}>
                    <div className="about_text">
                      <h3>About</h3>
                      <img src="/images/retu_signature2.png" alt="" />
                      <h4>{data.home.section3.heading}</h4>

                      <p dangerouslySetInnerHTML={{ __html: data?.home.section3?.text }}></p>

                      <button onClick={() => navigate(`/${data?.home.section3?.link}`)}>
                        MORE DETAILS
                      </button>
                    </div>
                  </Col>
                  <Col md={5}>
                    <div>
                      <img src={`${cloudUrl}/${data?.home.section3.image.filename}`} alt="" />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="services_main">
          <Container>
            <Row>
              <Col md={12}>
                <div className="services_heading">
                  <h1>
                    Our <br /> Services
                  </h1>
                  <img src="/images/retu_signature1.png" alt="" />
                </div>
                {!servicesDataLoading && servicesData?.service ? <Row className="d-flex justify-content-center">
                  {servicesData.service.map((service, index) => (
                    <Col key={index} md={4}>
                      <div className="service_box">
                        <img src={`${cloudUrl}/${service.section1.image.filename}`} alt={`${service.section1.image.filename}`} />
                        <h5>{service.section2.heading}</h5>
                        <div className="text-center">
                          <button onClick={() => navigate(`service/${service.slug}`)}>
                            More Details
                          </button>
                        </div>
                      </div>
                    </Col>
                  ))}
                  {/* <Col md={4}>
                    <div className="service_box">
                      <img src={serviceBox3} alt="servicebox3.png" />
                      <h5>ONLINE SUPPORT</h5>
                      <div className="text-center">
                        <button>
                          Coming soon
                        </button>
                      </div>
                    </div>
                  </Col> */}
                  <Col md={4}>
                    <div className="service_box">
                      <img src={serviceBox2} alt="servicebox2.png" />
                      <h5>WORKSHOPS & EVENTS</h5>
                      <div className="text-center">
                        <button>
                          Coming soon
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row> : null}


              </Col>
            </Row>
          </Container>
        </div>
      </> : <div style={{ height: "80vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Loader />
      </div>}




      <FeedbackPage />

      {/* <div className="blog_main">
        <Container>
          <h1>OUR BLOGS</h1>
          <Row className="justify-content-center">
            {homeBlogsData.map((blog, index) => (
              <Col key={index} md={3}>
                <div className="blog_box">
                  <img src={blog.imageSrc} alt="" style={{ height: "325px", width: "100%", objectFit: "cover" }} />
                  <div>
                    <p dangerouslySetInnerHTML={{ __html: blog.content }}></p>
                    <a href={`/blogs/${index + 1}`}>
                      Read More
                    </a>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div> */}

      <OurBlog />

      <Footer />
    </React.Fragment>
  );
};

export default Home;
/*<div class="show_mob"><nav class="bg-body-tertiary navbar navbar-expand-lg navbar-light"><div class="container"><span class="navbar-brand"></span><button aria-controls="basic-navbar-nav" type="button" aria-label="Toggle navigation" class="navbar-toggler"><span class="navbar-toggler-icon"></span></button><div class="navbar-collapse collapse show" id="basic-navbar-nav" style=""><div class="me-auto navbar-nav"><a role="button" class="nav-link" tabindex="0" href="#">Home</a><a role="button" class="nav-link" tabindex="0" href="#">About</a><a role="button" class="nav-link" tabindex="0" href="#"><div class="menu_dropdown"><span>Services <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M16.293 9.293 12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z"></path></svg> </span></div></a><a role="button" class="nav-link" tabindex="0" href="#"><li><div class="menu_dropdown" style="margin-right: 25px;"><span><span>Blogs</span> <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M16.293 9.293 12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z"></path></svg></span></div></li></a><a role="button" class="nav-link" tabindex="0" href="#">Contact Us</a></div></div></div></nav></div>*/
