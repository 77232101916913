import React, { useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import Loader from '../../../../Helper/Loader'
import Section1 from './Section1/Section1.js'
import Section2 from './Section2/Section2.js'
import { useGetAdminBlogPageQuery, useUpdateAdminBlogPageMutation } from '../../../../api/adminHome.js'


const BlogPage = () => {

    const { data, isLoading } = useGetAdminBlogPageQuery();
    const [activeTab, setActiveTab] = useState("SECTION1");
    let showSection = null;
    if (data) {
        if (activeTab === "SECTION1") showSection = <Section1 data={data?.blogPage?.section1} />;
        else if (activeTab === "SECTION2") showSection = <Section2 data={data?.blogPage?.section2} />;
    }
    return (
        <div className='page_responsive'>
            <h1>Blog Page</h1>
            {(!isLoading && data.blogPage) ?
                <Container style={{ backgroundColor: "#f6dbe2a6", padding: "20px", marginLeft: 0 }}>
                    <Row className='home-section-container'>
                        <Col md={2}>
                            <div className={"tabs_btn"}>
                                <Button className={activeTab === "SECTION1" ? "active" : ""} onClick={() => setActiveTab("SECTION1")}>
                                    Section 1
                                </Button>
                                <Button className={activeTab === "SECTION2" ? "active" : ""} onClick={() => setActiveTab("SECTION2")}>
                                    Section 2
                                </Button>
                            </div>
                        </Col>
                        <Col md={10}>
                            <div className={"tabs_description"}>{showSection}</div>
                        </Col>
                    </Row>
                </Container> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Loader />
                </div>
            }

        </div>
    )
}

export default BlogPage
