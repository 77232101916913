import React from 'react'
import { Container } from 'react-bootstrap'

const FooterEmails = () => {
    return (
        <div className='page_responsive'>
            <h1>Footer Emails</h1>
            <Container className='profile-container' style={{ backgroundColor: "#f6dbe2a6", padding: "20px", marginLeft: 0 }}>


            </Container>
        </div>
    )
}

export default FooterEmails
