import React, { useState } from 'react'
import { formats, modules } from '../../../../../Helper/Helper';
import { errorNotify, successNotify } from '../../../../../Helper/Toast';
import { Button, Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import { useUpdateAdminBlogPageMutation } from '../../../../../api/adminHome';

const Section1 = ({ data },) => {

    const [adminBlogPageUpdate] = useUpdateAdminBlogPageMutation();

    const [sectionData, setSectionData] = useState({
        heading: data.heading,
        text: data.text,
    })
    const [isLoading, setIsLoading] = useState(false)

    const onDataChange = (e, name) => {
        setSectionData((prev) => { return { ...prev, [name]: e.target.value } })
    }
    const onTextChange = (value) => {
        setSectionData((prev) => { return { ...prev, text: value } })
    }
    const handleSectionUpdate = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formData = new FormData();
        formData.append("sectionName", `section1`);
        formData.append("heading", sectionData.heading);
        formData.append("text", sectionData.text);
        setSectionData({
            heading: "",
            text: "",
        })
        const res = await adminBlogPageUpdate({ formData });
        if (res.data) {
            successNotify("Blog Page Section 1 Updated");
            setSectionData({
                heading: res?.data?.blog?.section1?.heading,
                text: res?.data?.blog?.section1?.text,
            })

        }
        else {
            errorNotify("Something went wrong")
        }
        setIsLoading(false);
    }

    return (
        <div>
            <Form onSubmit={handleSectionUpdate}>
                <Row>
                    <Col md={12}>
                        <Form.Label>Main Heading</Form.Label>
                        <InputGroup className="mb-3">
                            <Form.Control placeholder='Enter Heading Name' name='heading' value={sectionData?.heading} onChange={(e) => onDataChange(e, "heading")} />
                        </InputGroup>
                    </Col>
                    <Col md={12}>
                        <Form.Label>Text</Form.Label>
                        <ReactQuill style={{ backgroundColor: "white" }} name="text" className="mb-3" theme="snow" modules={modules} formats={formats} value={sectionData?.text} onChange={onTextChange} />
                    </Col>
                </Row>
                <hr />
                <div className={"text-end my-4"}>
                    <Button type='Submit' className="cms_submit_btn">{isLoading ? <Spinner animation='border' size='sm' /> : 'Submit'} </Button>
                </div>
            </Form>
        </div>
    )
}


export default Section1
