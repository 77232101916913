import React, { useState } from 'react';
import { Col, Container, Row, Button } from "react-bootstrap";
import "./About.css"
import Section1 from './Sections/Section1/Section1.js';
import Section2 from './Sections/Section2/Section2.js';
import Section3 from './Sections/Section3/Section3.js';
import Section4 from './Sections/Section4/Section4.js';



import { useGetAdminAboutQuery, useUpdateAdminAboutMutation, } from '../../../../api/adminHome';
import Loader from '../../../../Helper/Loader';

const EditAbout = () => {
    const { data, isLoading } = useGetAdminAboutQuery();

    console.log(data, "data");
    const [adminAboutUpdate] = useUpdateAdminAboutMutation();

    const [activeTab, setActiveTab] = useState("SECTION1");
    const [updateRes, setUpdateRes] = useState();
    const handleAboutUpdate = async ({ e, section, sectionData, images = null, addImage = null }) => {

        e.preventDefault();
        const formData = new FormData();
        formData.append("sectionName", `section${section}`);

        if (section === 1) {
            formData.append("heading", sectionData.heading)
            formData.append("text", sectionData.text)
            if (sectionData.image) {
                formData.append("image", sectionData.image)
            }
            const res = await adminAboutUpdate({ formData });
            setUpdateRes(res);
        }
        else if (section === 2) {
            formData.append("heading", sectionData.heading)
            formData.append("text", sectionData.text)
            formData.append("link", sectionData.url)
            if (sectionData.image) {
                formData.append("image", sectionData.image)
            }
            const res = await adminAboutUpdate({ formData });
            setUpdateRes(res);
        }
        else if (section === 3 || section === 4) {
            console.log(images);
            console.log(sectionData);
            formData.append("sectionData", JSON.stringify(sectionData));
            images.forEach(image => {
                formData.append("image", image);
            });
            const res = await adminAboutUpdate({ formData });
            setUpdateRes(res);
        }
        else if (section === 5) {
            formData.append("link", sectionData)
            const res = await adminAboutUpdate({ formData });
            setUpdateRes(res);
        }
    }
    const handleAboutAdd = () => {

    }
    const handleAboutDelete = () => {

    }

    let showSection = null;
    if (isLoading) {
        showSection = null
    }
    else if (data) {
        if (activeTab === "SECTION1") showSection = <Section1 updateRes={updateRes} data={data.about?.section1} onUpdate={handleAboutUpdate} />;
        else if (activeTab === "SECTION2") showSection = <Section2 updateRes={updateRes} data={data.about?.section2} onUpdate={handleAboutUpdate} />;
        else if (activeTab === "SECTION3") showSection = <Section3 updateRes={updateRes} data={data.about?.section3} onUpdate={handleAboutUpdate} onDelete={handleAboutDelete} />;
        else if (activeTab === "SECTION4") showSection = <Section4 updateRes={updateRes} section5data={data.about.section5} data={data.about?.section4} onUpdate={handleAboutUpdate} onDelete={handleAboutDelete} />;
    }



    return (
        <div className='page_responsive'>
            <h1>About</h1>
            {(!isLoading && data.about) ?
                <Container style={{ backgroundColor: "#f6dbe2a6", padding: "20px", marginLeft: 0 }}>
                    <Row className='home-section-container'>
                        <Col md={2}>
                            <div className={"tabs_btn"}>
                                <Button className={activeTab === "SECTION1" ? "active" : ""} onClick={() => setActiveTab("SECTION1")}>
                                    Section 1
                                </Button>
                                <Button className={activeTab === "SECTION2" ? "active" : ""} onClick={() => setActiveTab("SECTION2")}>
                                    Section 2
                                </Button>
                                <Button className={activeTab === "SECTION3" ? "active" : ""} onClick={() => setActiveTab("SECTION3")}>
                                    Section 3
                                </Button>
                                <Button className={activeTab === "SECTION4" ? "active" : ""} onClick={() => setActiveTab("SECTION4")}>
                                    Section 4
                                </Button>
                            </div>
                        </Col>
                        <Col md={10}>
                            <div className={"tabs_description"}>{showSection}</div>
                        </Col>
                    </Row>
                </Container> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Loader />
                </div>
            }

        </div>
    )
}
export default EditAbout;