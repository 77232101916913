import React, { useState } from 'react'
import { Button, Container, } from 'react-bootstrap'
import { useGetAdminTestimonialQuery } from '../../../../api/adminHome';
import Loader from '../../../../Helper/Loader';
import TestimonialModal from './TestimonialModal.js';
import TestimonialData from './TestimonialData.js';

const Testimonials = () => {
    const { data, isLoading } = useGetAdminTestimonialQuery();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const testimonialModal = <TestimonialModal show={show} handleClose={handleClose} />
    return (
        <div className='page_responsive'>
            <h1>Testimonials</h1>
            {(!isLoading && data.testimonial) ?
                <Container style={{ backgroundColor: "#f6dbe2a6", padding: "20px", marginLeft: 0 }}>
                    <div className={"text-end my-4"}>
                        <Button type='Submit' onClick={handleShow} className="cms_submit_btn">Create </Button>
                    </div>
                    {data.testimonial?.map((item) => {
                        return (
                            <TestimonialData testimonial={item} />
                        )
                    })}
                </Container> :
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Loader />
                </div>
            }
            {testimonialModal}

        </div>
    )
}

export default Testimonials
