import React, { useState } from "react";
import "./Footer.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useCreateNewsLetterEmailMutation, useGetAdminHomeQuery } from "../../api/adminHome";
import Loader from "../../Helper/Loader";
import { cloudUrl } from "../../Helper/Helper";
import { errorNotify, successNotify } from "../../Helper/Toast";

const Footer = () => {
  const { data, error, isLoading } = useGetAdminHomeQuery();
  const navigate = useNavigate();
  const [createNewsEmail] = useCreateNewsLetterEmailMutation();
  const [email, setEmail] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email === '') {
      errorNotify('Enter Email');
      return;
    }
    if (!emailRegex.test(email)) {
      errorNotify('Invalid email address');
      return;
    }
    try {
      const res = await createNewsEmail({ email: email });
      if (res?.data?.success) {
        successNotify("News Letter Subscribed Successfully")
      }
      else {
        errorNotify("Something went wrong")
      }
      setEmail("");
    } catch (error) {
      console.error('Failed to subscribe:', error);
    }
    console.log(`Subscribing with email: ${email}`);
  };

  return (
    <>
      {(!isLoading && data?.home) ? <div className="footer_main">
        <Container>
          <Row className="justify-content-center">
            <Col md={10}>
              <div className="get_started">
                <img src={`${cloudUrl}/${data.home.section4.image?.filename}`} alt={`${data.home.section4.image?.filename}`} />
                <button onClick={() => navigate(`${data.home.section4.link}`)}>Get Started</button>
              </div>
            </Col>
          </Row>
        </Container>

        <div className="footer_details">
          <Container fluid className="p-0">
            <Row className="align-items-center">
              <Col md={9}>
                <div className="footer_left">
                  <Container>
                    <Row
                      style={{
                        borderBottom: "2px solid #ecefee",
                        paddingBottom: "15px",
                      }}
                    >
                      <Col md={8}>
                        <div>
                          <ul>
                            <li onClick={() => navigate("/")}>Home</li>
                            <li onClick={() => navigate("/about")}>About</li>
                            <li onClick={() => navigate("/service/rs-method-1-1")}>
                              The RS Method
                            </li>
                            <li onClick={() => navigate("/blog")}>Blogs</li>
                            <li onClick={() => navigate("/contact-us")}>Contact Us</li>
                            {/* <li onClick={() => navigate("/testimonials")}>
                            Testimonials
                          </li> */}
                            {/* <li onClick={() => navigate("/login")}>Sign In</li> */}
                          </ul>

                          <h2>Let Me Slide In To Your Inbox</h2>
                          <p>
                            Sign up to receive free resources and my thoughts on everything from emotional eating to
                            body image to recipes.
                          </p>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="newsletter_div">
                          <h4>NEWSLETTER SIGN UP</h4>

                          <div className="email_div">
                            <label>Email</label>
                            <input type="email" onChange={handleEmailChange} value={email} />
                          </div>
                          <div>
                            <button onClick={handleSubscribe} className="sub_btn">Subscribe</button>
                          </div>

                          <div className="social_media">
                            <h6>Follow Links</h6>

                            <div className="d-flex gap-3 align-items-center">
                              <Link to={'https://www.instagram.com/reetusukhramani/'} target="_blank">
                                <img src="/images/insta_icon.png" alt="" style={{ width: "18px" }} />
                              </Link>
                              {/* <img
                              src="/images/insta_icon.png"
                              alt=""
                              style={{ width: "18px" }}
                            /> */}
                              {/* <img
                              src="/images/fb_icon.png"
                              alt=""
                              style={{ width: "10px" }}
                            />
                            <img
                              src="/images/twitter_icon.png"
                              alt=""
                              style={{ width: "20px" }}
                            />
                            <img
                              src="/images/youtube_icon.png"
                              alt=""
                              style={{ width: "22px" }}
                            /> */}
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <h5>©Reetu Sukhramani. All right Reversed.</h5>
                  </Container>
                </div>
              </Col>
              <Col md={3}>
                <div className="footer_signature">
                  <img src="/images/retu_signature1.png" alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div> : <div style={{ height: "80vh", display: "flex", justifyContent: "center", alignItems: "center" }} > <Loader /></div>}
    </>
  );
};

export default Footer;
