import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Blog.css";
import OtherFooter from "../../../Components/OtherFooter/OtherFooter";
import { useState } from "react";
import Header from "../../../Components/Header/Header";
import TestimonialHeader from "../../../Components/Header/TestimonialHeader";
import OurBlog from "../../../Components/Blogs/OurBlog";
import { useGetAdminBlogPageQuery, useGetAdminBlogQuery } from "../../../api/adminHome";
import Loader from "../../../Helper/Loader";
import { cloudUrl } from "../../../Helper/Helper";

const Blog = () => {
  const navigate = useNavigate();
  const [headerShow, setHeaderShow] = useState(true);
  const { data, isLoading } = useGetAdminBlogQuery();
  const { data: blogPageData, isLoading: isBlogPageLoading } = useGetAdminBlogPageQuery();
  return (
    <React.Fragment>
      <Container fluid className="p-0">
        <Row>
          <TestimonialHeader />
        </Row>
        <Row className="mob_nav">
          <Header
            headerShow={headerShow}
            setHeaderShow={setHeaderShow}
            goToQuote={null}
            showQuote={false}
          />
        </Row>
      </Container>

      <div className="about_main about_page blog_page">
        <Container>
          <Row className="justify-content-center">
            {!isLoading && data.blogs ?
              <>
                {data.blogs.map((item) => {
                  if (item.main) {
                    var parser = new DOMParser();
                    var doc = parser.parseFromString(item.text, "text/html");
                    var firstParagraphText = doc.querySelector('p').textContent;
                    console.log(firstParagraphText);
                    return (
                      <Col key={item._id} md={10}>
                        <Row className="align-items-center">
                          <Col md={6}>
                            <div>
                              <img src={`${cloudUrl}/${item?.image?.filename}`}
                                alt={`${item?.image?.filename}`} />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="about_text">
                              <h3>
                                {item.heading}
                              </h3>
                              <p>
                                {firstParagraphText}
                              </p>
                              <button onClick={() => navigate(`/blog/${item._id}`)} >Read Post</button>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    )
                  }
                  else {
                    return null
                  }

                })}
              </>
              : <div style={{ height: "80vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Loader />
              </div>}
          </Row>
        </Container>
      </div>

      <div className="food_blog">
        {!isBlogPageLoading && blogPageData?.blogPage ? <Container>
          <h5>WELCOME TO</h5>
          <h1>{blogPageData?.blogPage?.section1?.heading}</h1>
          <div></div>
          <p dangerouslySetInnerHTML={{ __html: blogPageData?.blogPage?.section1?.text }}></p>
        </Container> : <div style={{ height: "80vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Loader />
        </div>}

      </div>

      <div className="trending_category">
        <div className="trending_main">
          <Container>
            <h1>Now Trending</h1>

            <div className="now_trending">
              <Row>
                {!isLoading && data.blogs ? <>
                  {data.blogs.map((item) => {
                    if (item.trending) {
                      return (
                        <Col key={item._id} onClick={() => navigate(`/blog/${item._id}`)} md={4}>
                          <div className="trending_box" >
                            <img src={`${cloudUrl}/${item?.image?.filename}`}
                              alt={`${item?.image?.filename}`} />
                            <div className="trending_text">
                              <h6>Archive</h6>
                              <h5>{item?.heading}</h5>
                            </div>
                          </div>
                        </Col>
                      )
                    }
                    else {
                      return null;
                    }

                  })}
                </>


                  : <div style={{ height: "80vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Loader />
                  </div>}</Row>

            </div>
          </Container>
        </div>

        <div className="category_main">
          <Container>
            <Row className="justify-content-center">
              {!isBlogPageLoading && blogPageData.blogPage ?
                <Col md={5}>
                  <div className="category_text">
                    <h6>BROWSE BY CATEGORY</h6>
                    <ul>
                      <p dangerouslySetInnerHTML={{ __html: blogPageData?.blogPage?.section2?.text }}></p>
                    </ul>
                  </div>
                </Col> : <div style={{ height: "80vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Loader />
                </div>}

              <Col md={5}>
                <div>
                  <img src={`${cloudUrl}/${blogPageData?.blogPage.section2?.image?.filename}`}
                    alt={`${blogPageData?.blogPage.section2?.image?.filename}`} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <OurBlog />
      <OtherFooter />
    </React.Fragment>
  );
};

export default Blog;