import React, { useState } from 'react';
import { Col, Container, Row, Button } from "react-bootstrap";
import "./Home.css"
import Section1 from './Sections/Section1/Section1';
import Section2 from './Sections/Section2/Section2';
import Section3 from './Sections/Section3/Section3';
import Section4 from './Sections/Section4/Section4';
import { useGetAdminHomeQuery, useUpdateAdminHomeMutation } from '../../../../api/adminHome';
import Loader from '../../../../Helper/Loader';

const EditHome = () => {
    const { data, isLoading } = useGetAdminHomeQuery();
    const [adminHomeUpdate] = useUpdateAdminHomeMutation();
    const [activeTab, setActiveTab] = useState("SECTION1");
    const [updateRes, setUpdateRes] = useState();

    const handleHomeUpdate = async ({ e, section, sectionData }) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("sectionName", `section${section}`)
        if (sectionData.image) {
            formData.append("image", sectionData.image);
        }
        if (section === 1 || section === 3) {
            formData.append("heading", sectionData.heading)
            formData.append("text", sectionData.text)
        }
        else if (section === 2) {
            formData.append("text", sectionData.text)
        }
        formData.append("link", sectionData.url);

        const res = await adminHomeUpdate({ formData });
        setUpdateRes(res);
    }
    let showSection = null;
    if (data) {
        if (activeTab === "SECTION1") showSection = <Section1 updateRes={updateRes} data={data?.home?.section1} onChange={handleHomeUpdate} />;
        else if (activeTab === "SECTION2") showSection = <Section2 updateRes={updateRes} data={data?.home?.section2} onChange={handleHomeUpdate} />;
        else if (activeTab === "SECTION3") showSection = <Section3 updateRes={updateRes} data={data?.home?.section3} onChange={handleHomeUpdate} />;
        else if (activeTab === "SECTION4") showSection = <Section4 updateRes={updateRes} data={data?.home?.section4} onChange={handleHomeUpdate} />;
    }


    return (
        <div className='page_responsive'>
            <h1>Home</h1>
            {(!isLoading && data.home) ?
                <Container style={{ backgroundColor: "#f6dbe2a6", padding: "20px", marginLeft: 0 }}>
                    <Row className='home-section-container'>
                        <Col md={2}>
                            <div className={"tabs_btn"}>
                                <Button className={activeTab === "SECTION1" ? "active" : ""} onClick={() => setActiveTab("SECTION1")}>
                                    Section 1
                                </Button>
                                <Button className={activeTab === "SECTION2" ? "active" : ""} onClick={() => setActiveTab("SECTION2")}>
                                    Section 2
                                </Button>
                                <Button className={activeTab === "SECTION3" ? "active" : ""} onClick={() => setActiveTab("SECTION3")}>
                                    Section 3
                                </Button>
                                <Button className={activeTab === "SECTION4" ? "active" : ""} onClick={() => setActiveTab("SECTION4")}>
                                    Section 4
                                </Button>
                            </div>
                        </Col>
                        <Col md={10}>
                            <div className={"tabs_description"}>{showSection}</div>
                        </Col>
                    </Row>
                </Container> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Loader />
                </div>
            }

        </div>
    )
}
export default EditHome;