import React, { useEffect, useState } from 'react'
import { Button, Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap'
import defaultImage from '../../../../assets/retu_signature1.png'
import ReactQuill from 'react-quill'
import { cloudUrl, formats, modules } from '../../../../Helper/Helper'
import { useDeleteAdminTestimonialMutation, useUpdateAdminTestimonialMutation } from '../../../../api/adminHome'
import { errorNotify, successNotify } from '../../../../Helper/Toast'

const TestimonialData = ({ testimonial }) => {
    const [item, setItem] = useState();

    const [image, setImage] = useState('');
    const [imagePreview, setImagePreview] = useState();

    const [editTestimonial] = useUpdateAdminTestimonialMutation();
    const [deleteTestimonial] = useDeleteAdminTestimonialMutation();


    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);


    const handleTestimonialDelete = async (e) => {
        e.preventDefault();
        setIsDeleteLoading(true);
        const res = await deleteTestimonial({ id: item._id });

        if (res.data) {
            successNotify('Testimonial Deleted Successfully')
        }
        else {
            errorNotify("Something went wrong")
        }
        setIsDeleteLoading(false);
    }
    const handleTestimonialEdit = async (e) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('createdBy', item.createdBy);
        formData.append('text', item.text);
        if (image) {
            formData.append('image', image);
        }
        const res = await editTestimonial({ id: item._id, formData });
        console.log(res);
        if (res.data) {
            console.log(res.data);
            successNotify('Testimonial Updated Successfully')
        }
        else {
            errorNotify("Something went wrong")
        }
        setIsLoading(false);
    }
    const handleDataChange = (e, name) => {
        setItem((prev) => { return { ...prev, [name]: e.target.value } })
    }
    const onTextChange = (value) => {
        setItem((prev) => { return { ...prev, text: value } })
    };
    useEffect(() => {
        setItem(testimonial)
    }, [testimonial])

    const imageHandler = (e) => {
        const file = e.target.files[0];
        setImage(file)
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setImagePreview(event.target.result);
            };
            reader.readAsDataURL(file);
        }


    }

    return (
        <>

            <h4 style={{ backgroundColor: "#657f76", padding: "10px", borderRadius: "15px", color: "whitesmoke" }} >Testimonial</h4>
            <Form.Group controlId="formFile" className="mb-3">
                <Row>
                    <Col md={12} >
                        <Form.Label style={{
                            fontWeight: "bold", marginLeft: "5px", marginTop: "15px"
                        }} >Created By</Form.Label>
                        <InputGroup className="mb-3">
                            <Form.Control placeholder='Enter Heading Name' name='createdBy' value={item?.createdBy} onChange={(e) => handleDataChange(e, 'createdBy')} />
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} >
                        <Form.Label style={{ fontWeight: "bold", marginLeft: "5px", marginTop: "15px" }}>Image</Form.Label>
                        <Form.Control type="file" style={{ fontSize: "13px", paddingBottom: "7px" }} onChange={imageHandler} />
                        <img src={imagePreview ? imagePreview : `${cloudUrl}/${item?.image?.filename}`} alt={`${item?.image?.filename}`} style={{ width: "120px" }} />
                    </Col>
                </Row>
                <Row>
                    <Col md={12} >
                        <Form.Label style={{ fontWeight: "bold", marginLeft: "5px", marginTop: "15px" }}>Text</Form.Label>
                        <ReactQuill placeholder='Enter Blog text' style={{ backgroundColor: "white" }} name="text" className="mb-3" theme="snow" modules={modules} formats={formats}
                            value={item?.text} onChange={onTextChange} />
                    </Col>
                </Row>
                <div className={"text-end my-4"}>
                    <Button type='Submit'
                        disabled={isLoading || isDeleteLoading ? true : false}
                        onClick={handleTestimonialEdit}
                        className="cms_submit_btn">{isLoading ? <Spinner animation='border' size='sm' /> : "Edit"}</Button>
                    <Button type='Submit'
                        onClick={handleTestimonialDelete}
                        disabled={isLoading || isDeleteLoading ? true : false}
                        className="cms_submit_btn">{isDeleteLoading ? <Spinner animation='border' size='sm' /> : 'Delete'}</Button>
                </div>
            </Form.Group>
        </>
    )
}

export default TestimonialData
