import React, { useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap';
import Section1 from './Section/Section1.js'
import Section2 from './Section/Section2.js'
import Section3 from './Section/Section3.js'
import Section4 from './Section/Section4.js'
import Section5 from './Section/Section5.js'
import Section6 from './Section/Section6.js'
import Section7 from './Section/Section7.js'
import Section8 from './Section/Section8.js'
import SectionFAQS from './Section/SectionFAQS.js'


const ServiceData = ({ service }) => {
    console.log(service);
    const [activeTab, setActiveTab] = useState("SECTION1");

    let showSection = null;
    if (service) {
        if (activeTab === "SECTION1") showSection = <Section1 data={service?.section1} id={service?._id} />;
        else if (activeTab === "SECTION2") showSection = <Section2 data={service?.section2} id={service?._id} />;
        else if (activeTab === "SECTION3") showSection = <Section3 data={service?.section3} id={service?._id} />;
        else if (activeTab === "SECTION4") showSection = <Section4 data={service?.section4} id={service?._id} />;
        else if (activeTab === "SECTION5") showSection = <Section5 data={service?.section5} id={service?._id} />;
        else if (activeTab === "SECTION6") showSection = <Section6 data={service?.section6} id={service?._id} />;
        else if (activeTab === "SECTION7") showSection = <Section7 data={service?.section7} id={service?._id} />;
        else if (activeTab === "SECTION8") showSection = <Section8 data={service?.section8} id={service?._id} />;
    }
    return (
        <Container style={{ padding: "20px", marginLeft: 0 }}>
            <h4 style={{ backgroundColor: "#657f76", padding: "10px", borderRadius: "15px", color: "whitesmoke" }} >Service</h4>
            <Row >
                <Col md={2}>
                    <div className={"tabs_btn"}>
                        <Button className={activeTab === "SECTION1" ? "active" : ""} onClick={() => setActiveTab("SECTION1")}>
                            Section 1
                        </Button>
                        <Button className={activeTab === "SECTION2" ? "active" : ""} onClick={() => setActiveTab("SECTION2")}>
                            Section 2
                        </Button>
                        <Button className={activeTab === "SECTION3" ? "active" : ""} onClick={() => setActiveTab("SECTION3")}>
                            Section 3
                        </Button>
                        <Button className={activeTab === "SECTION4" ? "active" : ""} onClick={() => setActiveTab("SECTION4")}>
                            Section 4
                        </Button>
                        <Button className={activeTab === "SECTION5" ? "active" : ""} onClick={() => setActiveTab("SECTION5")}>
                            Section 5
                        </Button>
                        <Button className={activeTab === "SECTION6" ? "active" : ""} onClick={() => setActiveTab("SECTION6")}>
                            Section 6
                        </Button>
                        <Button className={activeTab === "SECTION7" ? "active" : ""} onClick={() => setActiveTab("SECTION7")}>
                            Section 7
                        </Button>
                        <Button className={activeTab === "SECTION8" ? "active" : ""} onClick={() => setActiveTab("SECTION8")}>
                            Section 8
                        </Button>
                    </div>
                </Col>
                <Col md={10}>
                    <div className={"tabs_description"}>{showSection}</div>
                </Col>
            </Row>
            <h5 style={{ padding: "10px 0px 0px 40px", borderRadius: "15px" }} >Faqs</h5>
            <SectionFAQS id={service?._id} data={service.faqs} />
        </Container>
    )
}

export default ServiceData
