import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../Blog/BlogRegular/BlogRegular";
import OtherFooter from "../../../Components/OtherFooter/OtherFooter";
import Header from "../../../Components/Header/Header";
import TestimonialHeader from "../../../Components/Header/TestimonialHeader";
import { Link } from "react-router-dom";

const Blog2 = () => {
  const [headerShow, setHeaderShow] = useState(true);
  return (
    <div className="blog_regular">
      <Container fluid className="p-0">
        <Row>
          <TestimonialHeader />
        </Row>
        <Row className="mob_nav">
          <Header
            headerShow={headerShow}
            setHeaderShow={setHeaderShow}
            goToQuote={null}
            showQuote={false}
          />
        </Row>
      </Container>

      <div className="about_main about_page blog_regular_page blog-2">
        <Container className="SectionOne">
          <div className="image-container">
            {/* <img src="/images/BingeEating.png" alt="" /> */}
            <div>
              <h1>
                Why is working on your relationship with your body so important?
              </h1>
              <hr />
              <div>
                BY REETU SUKHRAMANI
              </div>
            </div>
          </div>
        </Container>
      </div>

      <Container fluid className="SectionTwo">
        <Row className="justify-content-center">
          <Col md={11} className="Main">
            <div className="Para">
              <p>
                The harsh truth about our bodies is that we will not always love it.
              </p>
              <p>
                And, as much as I'd like to march about and sing the "body positivity" anthem to every woman I know,
                it's practically hard for some of us to fathom a day when we can completely, wholeheartedly love, respect, and feel comfortable in our bodies.
              </p>

              <h3>So, what's the point? </h3>
              <p>
                Why bother working on something that is nearly impossible to repair?
              </p>
              <p>
                This is where we've gone wrong. Body positivity isn't the answer for most individuals, especially those who can't think of anything to be grateful for about their bodies.
              </p>
              <p>
                So, what do we do now? We move the goalposts closer to a more neutral landscape.
              </p>

              <p>
                A landscape where you can confront and challenge your inner narrative and work on becoming more accepting towards your body.
              </p>

              <h3>If this is you then use these prompts to figure out where you need to “do the work”:</h3>

              <ul className="SupportPeople">
                <li>What does my body mean to me?</li>
                <li>Where do I feel the most discomfort within my body?</li>
                <li>Since when have I felt this way about my body?</li>
                <li>Why are body aesthetic goals so important to me?</li>
                <li>Has it ever failed to show up for me?</li>
                <li>List a few functions of my body for which I be grateful for today</li>
              </ul>

              <p>If any of these helped or you want to dig a little deeper then fill in the form below and let’s chat? </p>

              <button className="blog_btn"> <Link to={"https://docs.google.com/forms/d/17P7hXZXLOLouePep2OEJg_pR0jjrd0OE775lQ3PE65Q/edit"} target="_blank">
                ENROLL NOW
              </Link>
              </button>
            </div>
          </Col>
        </Row>
      </Container>

      <OtherFooter />
    </div>
  );
};

export default Blog2;
