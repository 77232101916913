import React, { useState } from 'react'
import { Form, Button, Col, Row, Spinner, InputGroup } from 'react-bootstrap'
import { cloudUrl, formats, modules } from '../../../../../Helper/Helper.js';
import { useUpdateAdminServiceMutation } from '../../../../../api/adminHome.js';
import { errorNotify, successNotify } from '../../../../../Helper/Toast.js';
import ReactQuill from 'react-quill';


const Section7 = ({ data, id }) => {


    const [sectionData, setSectionData] = useState({
        image: data.image.filename,
        heading: data.heading,
        text: data.text
    })
    const onDataChange = (e, name) => {
        setSectionData((prev) => { return { ...prev, [name]: e.target.value } })
    }
    const onTextChange = (value) => {
        setSectionData((prev) => { return { ...prev, text: value } })

    }
    const [editSection] = useUpdateAdminServiceMutation();
    const [image, setImage] = useState();
    const [imagePreview, setImagePreview] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const imageHandler = (e) => {
        const file = e.target.files[0];
        setImage(file)
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setImagePreview(event.target.result);
            };
            reader.readAsDataURL(file);
        }
    }
    const handleEditSection = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formData = new FormData();
        formData.append("sectionName", `section7`);
        if (image) {
            formData.append('image', image);
        }
        formData.append('heading', sectionData.heading);
        formData.append('text', sectionData.text);

        const res = await editSection({ id: id, formData });
        if (res.data) {
            successNotify("Service Section Updated");
        }
        else {
            errorNotify("Something went wrong");
        }

        setIsLoading(false);

    }
    return (
        <div>
            <Form onSubmit={handleEditSection}>
                <Row>
                    <Col md={12}>
                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Image</Form.Label>
                            <Form.Control type="file" style={{ fontSize: "13px", paddingBottom: "7px" }} onChange={imageHandler} />
                        </Form.Group>
                        <img src={imagePreview ? imagePreview : `${cloudUrl}/${sectionData.image}`} alt={`${sectionData.image}`} style={{ width: "120px" }} />
                    </Col>
                    <Col md={12}>
                        <Form.Label>Main Heading</Form.Label>
                        <InputGroup className="mb-3">
                            <Form.Control placeholder='Enter Heading Name' name='heading' value={sectionData.heading} onChange={(e) => onDataChange(e, "heading")} />
                        </InputGroup>
                    </Col>
                    <Col md={12}>
                        <Form.Label>Text</Form.Label>
                        <ReactQuill style={{ backgroundColor: "white" }} name="text" className="mb-3" theme="snow" modules={modules} formats={formats} value={sectionData.text} onChange={onTextChange} />
                    </Col>
                </Row>
                <hr />
                <div className={"text-end my-4"}>
                    <Button type='Submit' className="cms_submit_btn">{isLoading ? <Spinner animation='border' size='sm' /> : 'Submit'} </Button>
                </div>
            </Form>
        </div>
    )
}

export default Section7
